import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_MAILER, SET_MAILERS} from "../../mt";
import store  from '@/store';
import {hasRight} from '@/utils/access/hasRight';

export default {
    getMailers(context) {
        if (hasRight('mailers.view'))
            return AxiosWrapper.get(`/api/mailers`).then((response) => {
                context.commit(SET_MAILERS, response.data.response);
                return response;
            });
    },
    getMailersPages (context, payload) {
        if (hasRight('mailers.view'))
            return AxiosWrapper.get(`/api/mailers`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_MAILERS, response.data.response.data);
                store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
                return response;
            });
    },
    getMailersBy (context, payload) {
        if (hasRight('mailers.view'))
            return AxiosWrapper.getWithoutLoading(`/api/mailers`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_MAILERS, response.data.response.data);
                return response.data.response;
            });
    },
    addMailersBy(context, payload) {
        if (hasRight('mailers.view'))
            return AxiosWrapper.getWithoutLoading(`/api/mailers`, {queryParams: {...payload}}).then((response) => {
                context.commit('ADD_MAILERS', response.data.response.data);
                return response.data.response;
            });
    },
    getMailer (context, payload) {
        return AxiosWrapper.get(`/api/mailers/${payload}`).then((response) => {
            context.commit(SET_MAILER, response.data.response);
            return response;
        });
    },
    editMailer (context, payload) {
        return AxiosWrapper.put(`/api/mailers/${payload.id}`, payload).then((response) => {
            context.commit(SET_MAILER, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    createMailer (context, payload) {
        return AxiosWrapper.post(`/api/mailers`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Рассылка успешно создана!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteMailer (context, payload) {
        return AxiosWrapper.delete(`/api/mailers/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Рассылка ${payload.name} была удалена`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    addFile (context, payload) {
        return AxiosWrapper.post(`/api/mailers/${payload.id}/attachments`, payload.formData).then((response) => {
            context.commit(SET_MAILER, response.data.response);
            return response;
        });
    },
    deleteFile (context, payload) {
        return AxiosWrapper.delete(`/api/mailers/${payload.id}/attachments/${payload.fileId}`, ).then((response) => {
            return response;
        });
    },
};
