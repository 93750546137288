import {
    SET_SCRIPT_GROUPS,
    SET_SCRIPTS,
    SET_GROUP_SCRIPTS,
    SET_CLIENT_CUSTOM_FIELDS_SCRIPTS,
    SET_DEAL_CUSTOM_FIELDS_SCRIPTS
} from "../../mt";

export default {
    [SET_SCRIPT_GROUPS](state, payload) {
        state.scriptGroups = payload;
    },
    [SET_SCRIPTS](state, payload) {
        state.scripts = payload;
    },
    [SET_GROUP_SCRIPTS](state, payload) {
        state.groupScripts = payload;
    },
    ['CHANGE_SCRIPT'](state, payload) {
        state.scripts.forEach((el) => {
            if (el.id === payload.id) {
                el.name = payload.name;
                el.text = payload.text;
                el.attachments = payload.attachments;
            }
        });
    },
    ['ADD_SCRIPT'](state, payload) {
        state.scripts.push(payload);
    },
    ['ADD_SCRIPT_TO_GROUP'](state, payload) {
        state.groupScripts.push(payload);
    },
    ['REMOVE_GROUP_SCRIPTS'](state) {
        state.groupScripts = [];
    },
    ['CHANGE_GROUP_SCRIPT'](state, payload) {
        state.groupScripts.forEach((el) => {
            if (el.id === payload.id) {
                el.attachments = payload.attachments;
            }
        });
    },
    [SET_CLIENT_CUSTOM_FIELDS_SCRIPTS](state, payload) {
        payload.map(i => {
            if (!state.keywords[0].values.some(k => k.text === i.name)) {
                state.keywords[0].values.push({
                    text: i.name,
                    value: `[Клиент.${i.name}]`,
                });
            }
        });
    },
    [SET_DEAL_CUSTOM_FIELDS_SCRIPTS](state, payload) {
        payload.map(i => {
            if (!state.keywords[1].values.some(k => k.text === i.name)) {
                state.keywords[1].values.push({
                    text: i.name,
                    value: `[Сделка.${i.name}]`,
                });
            }
        });
    },
};
