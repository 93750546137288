import {SET_CLIENT_STATUSES} from "../../mt";

export default {
    [SET_CLIENT_STATUSES](state, payload) {
        state.statuses = payload;
    },
    ['SET_CLIENT_STATUSES_CHANGES'](state, payload) {
        for (let i = 0; i < state.statuses.length; i++) {
            if (state.statuses[i].id === payload.id) {
                state.statuses.splice(i, 1, payload);
                break;
            }
        }
    },
};
