import {AxiosWrapper} from '@/api/AxiosWrapper';
import {
    SET_MAILS,
    SET_MAIL_FOLDERS,
    SET_MAIL_MESSAGE,
    SET_MAIL_MESSAGES,
    SET_MAIL_MESSAGES_TOTAL, SET_MAIL_SETTINGS
} from "../../mt";
import {hasRight} from "@/utils/access/hasRight";

export default {
    getMailBy (context, payload) {
        if (hasRight('mail.accessAll') || hasRight('mail.accessOwn')) {
            return AxiosWrapper.get(`/api/integrations/mail`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_MAILS, response.data.response.data);
                return response.data.response.data;
            });
        }
    },
    addMailBy (context, payload) {
        if (hasRight('mail.accessAll') || hasRight('mail.accessOwn')) {
            return AxiosWrapper.get(`/api/integrations/mail`, {queryParams: {...payload}}).then((response) => {
                context.commit('ADD_MAILS', response.data.response.data);
                return response.data.response.data;
            });
        }
    },
    getMailFolders(context, payload) {
        if (hasRight('mail.accessAll') || hasRight('mail.accessOwn')) {
            return AxiosWrapper.get(`/api/integrations/mail/${payload}/folders`).then((response) => {
                context.commit(SET_MAIL_FOLDERS, response.data.response);
                return response.data.response;
            });
        }
    },
    getMailMessages(context, payload) {
        if (hasRight('mail.accessAll') || hasRight('mail.accessOwn')) {
            const query = payload.query;
            query.page -= 1;
            return AxiosWrapper.get(`/api/integrations/mail/${payload.id}/messages`, {
                queryParams: payload.query
            }).then((response) => {
                context.commit(SET_MAIL_MESSAGES, response.data.response.messages);
                context.commit(SET_MAIL_MESSAGES_TOTAL, response.data.response.total);
                return response.data.response.messages;
            });
        }
    },
    getMailMessagesWithoutLoading(context, payload) {
        if (hasRight('mail.accessAll') || hasRight('mail.accessOwn')) {
            return AxiosWrapper.getWithoutLoading(`/api/integrations/mail/${payload.id}/messages`, {
                queryParams: {
                    per_page: payload.query.per_page,
                    page: payload.query.page - 1,
                    path: payload.query.path
                }
            }).then((response) => {
                context.commit(SET_MAIL_MESSAGES, response.data.response.messages);
                context.commit(SET_MAIL_MESSAGES_TOTAL, response.data.response.total);
                return response.data.response.messages;
            });
        }
    },
    getMailFoldersWithoutLoading(context, payload) {
        if (hasRight('mail.accessAll') || hasRight('mail.accessOwn')) {
            return AxiosWrapper.getWithoutLoading(`/api/integrations/mail/${payload}/folders`).then((response) => {
                context.commit(SET_MAIL_FOLDERS, response.data.response);
                return response.data.response;
            });
        }
    },
    getMailMessage(context, payload) {
        if (hasRight('mail.accessAll') || hasRight('mail.accessOwn')) {
        return AxiosWrapper.get(`/api/integrations/mail/${payload.mailId}/message`, {
            queryParams: {
                id: payload.id,
                path: payload.path
            }
        }).then((response) => {
            context.commit(SET_MAIL_MESSAGE, response.data.response);
            return response.data.response;
        });
        }
    },
    clearMessage(context) {
        context.commit(SET_MAIL_MESSAGE, null);
    },
    clearFolders(context) {
        context.commit(SET_MAIL_FOLDERS, []);
    },
    mailAction(context, payload) {
        return AxiosWrapper.post(`/api/integrations/mail/${payload.id}/action`, payload.body).then((response) => {
            return response.data.response;
        });
    },
    mailSend(context, payload) {
        if (hasRight('mail.send')) {
            return AxiosWrapper.post(`/api/integrations/mail/${payload.id}/send`, payload.body).then((response) => {
                context.commit(
                    'common/ADD_ALERT',
                    {
                        text: `Ваше сообщение отправлено!`,
                        type: 'success',
                        timeout: 2000,
                    },
                    {root: true}
                );
                return response.data.response;
            });
        }
    },
    downloadAttachment(context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/integrations/mail/${payload.mailId}/attachment`, {queryParams: {id: payload.fileId}}, {responseType: 'blob'}).then((response) => {
            return response;
        });
    },
    getSettings (context) {
        if (hasRight('mail.settings')) {
            return AxiosWrapper.getWithoutLoading(`/api/settings/mail`).then((response) => {
                context.commit(SET_MAIL_SETTINGS, response.data.response);
                return response.data.response;
            });
        }
    },
    setSettings (context, payload) {
        return AxiosWrapper.post(`/api/settings/mail`, payload).then((response) => {
            context.commit(SET_MAIL_SETTINGS, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data.response;
        });
    },
    setFolderIndicator (context, payload) {
        context.commit('SET_FOLDER_INDICATOR', payload);
    }
}
