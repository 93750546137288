import {
    SET_MAIL_FOLDERS,
    SET_MAIL_MESSAGE,
    SET_MAIL_MESSAGES,
    SET_MAIL_MESSAGES_TOTAL,
    SET_MAIL_SETTINGS, SET_MAILS
} from "../../mt";
const icons = [
    {
        name: 'inbox',
        values: ['inbox', 'входящие'],
    },
    {
        name: 'outbox',
        values: ['исходящие', 'outbox'],
    },
    {
        name: 'sent',
        values: ['sent', 'отправленные'],
    },
    {
        name: 'spam',
        values: ['spam', 'спам'],
    },
    {
        name: 'trash',
        values: ['корзина', 'trash'],
    },
    {
        name: 'drafts',
        values: ['drafts', 'черновики'],
    },
];

export default {
    [SET_MAIL_MESSAGES](state, payload) {
        state.messages = payload;
    },
    [SET_MAIL_MESSAGE](state, payload) {
        state.message = payload;
    },
    [SET_MAIL_MESSAGES_TOTAL](state, payload) {
        state.messagesTotal = payload;
    },
    [SET_MAIL_FOLDERS](state, payload) {
        state.folders = payload;
        state.folders.forEach((item) => {
            item.order = 2;
            if (item.name === 'INBOX') {
                item.title = 'Входящие';
                item.order = 1;
            } else if (item.name === 'Outbox') {
                item.title = 'Исходящие';
            } else if (item.name === 'Sent') {
                item.title = 'Отправленные';
            } else if (item.name === 'Drafts') {
                item.title = 'Черновики';
            } else if (item.name === 'Spam') {
                item.title = 'Спам';
            } else if (item.name === 'Trash') {
                item.title = 'Удаленные';
            } else if (item.name === '[Gmail]') {
                item.name = 'Gmail';
            } else {
                item.title = item.name;
            }

            //задаем название иконок
            for (const icon of icons) {
                if (icon.values.find((v) => v.toLowerCase() === item.name.toLowerCase())) {
                    item.icon = icon.name;
                }
            }
        });
        state.folders.sort((a, b) => a.order - b.order)
    },
    [SET_MAIL_SETTINGS](state, payload) {
        state.settings = payload;
    },
    [SET_MAILS](state, payload) {
        state.mails = payload;
    },
    ['ADD_MAILS'](state, payload) {
        payload.forEach(i => {
            if (!state.mails.some(e => e.id === i.id)) {
                state.mails.push(i);
            }
        });
    },
    ['SET_FOLDER_INDICATOR'](state, payload) {
        // console.warn(payload.folder)
        // console.warn(payload.unseen)
        state.folders.find(f => f.path === payload.folder.path).status.unseen = payload.unseen ;
    },
}
