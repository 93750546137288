import {SET_INTEGRATIONS, SET_INTEGRATION, SET_INTEGRATIONS_SETTINGS} from "../../mt";

export default {
    [SET_INTEGRATIONS](state, payload) {
        state.integrations = payload;
    },
    [SET_INTEGRATION](state, payload) {
        state.integration = payload;
    },
    ['ADD_INTEGRATIONS'](state, payload) {
        payload.forEach(i => {
            if (!state.integrations.some(e => e.id === i.id)) {
                state.integrations.push(i);
            }
        });
    },
    [SET_INTEGRATIONS_SETTINGS](state, payload) {
        state.settings = payload;
    },
};
