import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_PAYMENT_INFO} from "../../mt";

export default {
    getPlans () {
        return AxiosWrapper.get(`/api/plans`).then((response) => {
            return response.data.response;
        });
    },
    getPaymentsInfo (context) {
        return AxiosWrapper.getWithoutLoading(`/api/payments/info`).then((response) => {
            context.commit(SET_PAYMENT_INFO, response.data.response);
            return response.data.response;
        });
    },
    createPayment (context, payload) {
        return AxiosWrapper.post(`/api/payments/pay`, payload).then((response) => {
            return response.data;
        });
    },
    checkPromocode (context, payload) {
        return AxiosWrapper.post(`/api/payments/promocode`, payload).then((response) => {
            return response.data;
        });
    },
    removeActiveUser(context) {
        context.commit('REMOVE_ACTIVE_USER');
    },
    restoreActiveUser(context) {
        context.commit('RESTORE_ACTIVE_USER');
    },
}
