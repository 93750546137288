/////////////////////////////// COMMON //////////////////////////////////
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_MENU = 'SET_MENU';
export const START_REQUEST = 'START_REQUEST';
export const STOP_REQUEST = 'STOP_REQUEST';
export const SET_LOADING = 'SET_LOADING';
export const SET_TABLE_META = 'SET_TABLE_META';
/////////////////////////////// ANALYTICS //////////////////////////////////
export const SET_ANALYTICS = 'SET_ANALYTICS';
export const SET_ANALYTICS_ORGANIZATION = 'SET_ANALYTICS_ORGANIZATION';
/////////////////////////////// NOTIFICATIONS //////////////////////////////////
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_COUNT = 'SET_NOTIFICATIONS_COUNT';
/////////////////////////////// USER //////////////////////////////////
export const SET_PROFILE = 'SET_PROFILE';
export const SET_TENANT = 'SET_TENANT';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_WEBSOCKET_DATA = 'SET_WEBSOCKET_DATA';
/////////////////////////////// TASKS //////////////////////////////////
export const SET_TASKS = 'SET_TASKS';
export const SET_TASK = 'SET_TASK';
/////////////////////////////// DEALS //////////////////////////////////
export const SET_DEALS = 'SET_DEALS';
export const SET_DEAL = 'SET_DEAL';
export const SET_DEAL_CUSTOM_FIELDS = 'SET_DEAL_CUSTOM_FIELDS';
export const SET_DEAL_CUSTOM_FIELDS_SCRIPTS = 'SET_DEAL_CUSTOM_FIELDS_SCRIPTS';
export const SET_CDEK_TARIFFS = 'SET_CDEK_TARIFFS';
export const SET_CDEK_CITIES = 'SET_CDEK_CITIES';
export const SET_CDEK_OFFICES = 'SET_CDEK_OFFICES';
export const SET_DEAL_SETTINGS = 'SET_DEAL_SETTINGS';
/////////////////////////////// CLIENTS //////////////////////////////////
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_CLIENT = 'SET_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const SET_CLIENT_CUSTOM_FIELDS = 'SET_CLIENT_CUSTOM_FIELDS';
export const SET_CLIENT_CUSTOM_FIELDS_SCRIPTS = 'SET_CLIENT_CUSTOM_FIELDS_SCRIPTS';
/////////////////////////////// PRODUCTS //////////////////////////////////
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT = 'SET_PRODUCT';
/////////////////////////////// CHAT //////////////////////////////////
export const SET_CHATS = 'SET_CHATS';
export const SET_CHATS_FORWARD = 'SET_CHATS_FORWARD';
export const SET_CHAT = 'SET_CHAT';
export const SET_UPDATE_CLIENT = 'SET_UPDATE_CLIENT';
export const SET_CHATS_UNREAD_COUNT = 'SET_CHATS_UNREAD_COUNT';
export const SET_CHAT_INFO = 'SET_CHAT_INFO';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_REMINDERS = 'SET_REMINDERS';
export const SET_CHAT_SETTINGS = 'SET_CHAT_SETTINGS';
/////////////////////////////// MAILERS //////////////////////////////////
export const SET_MAILERS = 'SET_MAILERS';
export const SET_MAILER = 'SET_MAILER';
/////////////////////////////// USERS //////////////////////////////////
export const SET_USERS = 'SET_USERS';
export const SET_USER = 'SET_USER';
export const SET_SUBDIVISIONS = 'SET_SUBDIVISIONS';
export const SET_SUBDIVISION = 'SET_SUBDIVISION';
/////////////////////////////// EVENT LOG //////////////////////////////////
export const SET_EVENTS = 'SET_EVENTS';
/////////////////////////////// INTEGRATIONS //////////////////////////////////
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export const SET_INTEGRATION = 'SET_INTEGRATION';
export const SET_INTEGRATIONS_SETTINGS = 'SET_INTEGRATIONS_SETTINGS';
/////////////////////////////// SCRIPTS //////////////////////////////////
export const SET_SCRIPT_GROUPS = 'SET_SCRIPT_GROUPS';
export const SET_SCRIPTS = 'SET_SCRIPTS';
export const SET_GROUP_SCRIPTS = 'SET_GROUP_SCRIPTS';
/////////////////////////////// AUTOMATIONS //////////////////////////////////
export const SET_AUTOMATIONS = 'SET_AUTOMATIONS';
/////////////////////////////// PERMISSIONS //////////////////////////////////
export const SET_ROLES = 'SET_ROLES';
export const SET_RIGHTS = 'SET_RIGHTS';
/////////////////////////////// DEAL STATUSES //////////////////////////////////
export const SET_DEAL_STATUSES = 'SET_DEAL_STATUSES';
/////////////////////////////// PAYMENTS //////////////////////////////////
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
/////////////////////////////// BILLS //////////////////////////////////
export const SET_BILLS = 'SET_BILLS';
export const SET_BILL = 'SET_BILL';
/////////////////////////////// TAGS //////////////////////////////////
export const SET_TAGS = 'SET_TAGS';
/////////////////////////////// CLIENT STATUSES //////////////////////////////////
export const SET_CLIENT_STATUSES = 'SET_CLIENT_STATUSES';
/////////////////////////////// MAIL //////////////////////////////////
export const SET_MAILS = 'SET_MAILS';
export const SET_MAIL_MESSAGES = 'SET_MAIL_MESSAGES';
export const SET_MAIL_MESSAGE = 'SET_MAIL_MESSAGE';
export const SET_MAIL_FOLDERS = 'SET_MAIL_FOLDERS';
export const SET_MAIL_MESSAGES_TOTAL = 'SET_MAIL_MESSAGES_TOTAL';
export const SET_MAIL_SETTINGS = 'SET_MAIL_SETTINGS';
