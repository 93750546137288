<template>
  <v-menu offset-y :close-on-content-click="false" v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="#44D370" class="evi-button-filter-calendar mr-3" :class="isActiveFilter" v-bind="attrs" v-on="on">
        <img width="18" v-if="!isDark && !isActiveFilter" :src="require('@/assets/img/analytics/calendar-white.svg')" alt="">
        <img width="18" v-else :src="require('@/assets/img/analytics/calendar.svg')" alt="">
      </v-btn>
    </template>
    <div class="evi-calendar-menu__content">
      <h4>Фильтровать за период:</h4>
      <div class="d-flex flex-column">
        <div class="d-flex mt-5 mb-5">
          <v-text-field
              v-model="dateFromLocal"
              class="evi-calendar-menu__date-field"
              solo
              v-mask="'##.##.####'"
          >
            <template v-slot:prepend-inner>
              <span>С</span>
            </template>
          </v-text-field>
          <v-text-field
              v-model="dateToLocal"
              class="evi-calendar-menu__date-field"
              solo
              v-mask="'##.##.####'"
          >
            <template v-slot:prepend-inner>
              <span>По</span>
            </template>
          </v-text-field>
        </div>
        <v-date-picker
            v-model="dates"
            range
            color="#44D370"
            first-day-of-week="1"
            no-title
            class="mb-5"
        />
        <div class="d-flex justify-space-between align-center">
          <v-btn class="evi-button-green" @click="clear">
            Сбросить
          </v-btn>
          <v-btn class="evi-button-green evi-button-green--fill" @click="setDates">
            Применить
          </v-btn>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "EsDatePickerRange",
  props: {
    dateTo: {
      type: String,
    },
    dateFrom: {
      type: String,
    },
  },
  data: () => ({
    dates: [],
    dateFromLocal: '',
    dateToLocal: '',
    menu: false,
  }),
  computed: {
    isDark () {
      return this.$vuetify.theme.dark;
    },
    isActiveFilter () {
      if (this.dateFrom || this.dateTo) {
        return 'filter-active';
      }
      return '';
    },
  },
  methods: {
    toDateFormatter(date) {
      const splitDate = date.split('.');
      return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    },
    setDates () {
      this.dates[0] = this.toDateFormatter(this.dateFromLocal);
      this.dates[1] = this.toDateFormatter(this.dateToLocal);
      this.pickDates()
    },
    pickDates() {
      this.$emit('pickDates', this.dates[0], this.dates[1]);
      this.menu = false;
    },
    clear () {
      this.$emit('clearDates');
      this.$emit('pickDates', '', '');
      this.dates = [];
      this.menu = false;
      this.dateFromLocal = '';
      this.dateToLocal = '';
    },
    datesInit () {
      this.dateFromLocal = this.$moment(this.dates[0]).format('DD.MM.YYYY');
      this.dateToLocal = this.$moment(this.dates[1]).format('DD.MM.YYYY');
    }
  },
  mounted() {
    eventBus.$on('pickDatesAnalytics', (payload) => {
      this.dates[0] = payload.from;
      this.dates[1] = payload.to;

      this.pickDates();
    });
    if (!this.$route.query['date_from']) {
      this.dates[0] = this.$moment(new Date).format('YYYY-MM-DD');
      this.dates[1] = this.$moment(new Date).format('YYYY-MM-DD');
      this.datesInit();
    }
  },
  watch: {
    dates: {
      handler: function (val) {
        if (new Date(val[0]) > new Date(val[1])) {
          val.sort(function (a, b) {
            return new Date(a) - new Date(b);
          });
        }
        this.datesInit();
      },
      deep: true,
    },
    dateFrom (v) {
      if (v) {
        this.dates[0] = v;
        this.datesInit();
      }
    },
    dateTo (v) {
      if (v) {
        this.dates[1] = v;
        this.datesInit();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
