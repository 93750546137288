export var OpenPagesMixin = {
    methods: {
        openUserPage(id) {
            this.$router.push({path: `/users/${id}`})
        },
        openClientPage(id) {
            this.$router.push({path: `/clients/${id}`});
        },
        openItemPage(id) {
            this.$router.push({path: `${this.$route.path}/${id}`});
        },
        openSubdivisionPage(id) {
            this.$router.push({path: `/subdivisions/${id}`});
        },
        openDealPage(id) {
            this.$router.push({path: `/deals/${id}`});
        },
        openBillPage(id) {
            this.$router.push({path: `/bills/${id}`});
        },
        openIntPage(id) {
            if (this.$route.params.id === 'form') {
                this.$router.push({path: `/integrations/forms/${id}`});
            } else {
                this.$router.push({path: `/integrations/${id}`});
            }
        },
        openIntFormPage(id) {
            this.$router.push({path: `/integrations/forms/${id}`});
        },
        openUserAnalytics (id) {
            this.$store.dispatch('analytics/setUserId', id);
            this.$router.push({path: `/analytics/users`});
        },
    }
};
