import Vue from 'vue';
import VueEcho from 'vue-echo-laravel';
import store from "../store";

window.Pusher = require('pusher-js');

const api = process.env.VUE_APP_API_URL || location.protocol + '//' + location.host;
const apiWs = 'socket.evisales.com';

export const wsConnect = () => {
    let token = store.state.user.accessToken;
    let wsData = store.state.user.webSocketData;

    Vue.use(VueEcho, {
        broadcaster: 'pusher',
        disableStats: true,
        forceTLS: true,
        key: wsData.key,
        secret: wsData.secret,
        cluster: 'mt1',
        wsHost: `${apiWs}`,
        wssPort: 443,
        authEndpoint: `${api}/api/broadcasting/auth`,
        auth:
            {
                headers:
                    {
                        'Authorization': 'Bearer ' + token
                    }
            }
    });
};
