export default {
    menu: {},
    alerts: [],
    requests: [],
    loading: false,
    tableMeta: {
        page: 1,
        per_page: 10,
    },
};
