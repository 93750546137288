<template>
  <v-card class="modal-create settings">
    <v-form ref="form">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Общие настройки интеграций</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-radio-group
              v-model="integrationsClientsDistribution"
              class="evi-radio-button-group"
          >
            <template v-slot:label>
              Распределение клиентов
              <v-menu
                  top
                  offset-y
                  open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  По умолчанию, сотрудник сам выбирает клиента из всех написавших
                  <br>
                  Как только сотрудник отправляет первое сообщение клиенту,
                  <br>
                  он закрепляется за ним.
                  <br>
                  <br>
                  Если выбрать автоматическое распределение, то можно будет
                  <br>
                  задать теги для клиентов, которые будут поочередно распределяться на них.
                  <br>
                  Ответственный сотрудник увидит свой тег и заберет клиента, написав ему.
                </v-list-item>
              </v-menu>
            </template>
            <div class="d-flex align-center">
              <v-radio
                  v-for="item in clientsDistributionTypes"
                  :label="item.name"
                  :value="item.value"
                  :key="item.value"
                  class="mb-0 mr-6 evi-radio"
                  color="#45BF6A"
              />
            </div>
          </v-radio-group>
        </v-col>
        <v-col
            cols="12"
        >
          <v-radio-group
              v-model="integrationsUsersViewAllChats"
              class="evi-radio-button-group"
          >
            <template v-slot:label>
              Видимость диалогов и напоминаний
              <v-menu
                  top
                  offset-y
                  open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  По умолчанию, сотрудники видят всех написавших клиентов.
                  <br>
                  Когда за каким-то клиентом закрепляется сотрудник, то клиент пропадает
                  <br>
                  из поля зрения остальных сотрудников.
                  <br>
                  <br>
                  Выбирая видимость всех диалогов, сотрудник сможет видеть
                  <br>
                  все диалоги с клиентами и напоминания.
                  <br>
                  Это полезно, если сотруднику нужно помочь коллеге в диалоге с клиентом.
                </v-list-item>
              </v-menu>
            </template>
            <div class="d-flex align-center">
              <v-radio
                  v-for="item in usersViewChatTypes"
                  :label="item.name"
                  :value="item.value"
                  :key="item.value"
                  class="mb-0 mr-6 evi-radio"
                  color="#45BF6A"
              />
            </div>
          </v-radio-group>
        </v-col>
        <v-col
            cols="12"
        >
          <v-radio-group
              v-model="notificationsFromForm"
              class="evi-radio-button-group"
          >
            <template v-slot:label>
              Оповещения о новых клиентах с форм обратной связи
              <v-menu
                  top
                  offset-y
                  open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  Настройка регулирует получения уведомлений о новом клиенте при поступлении
                  <br>
                  обращения с формы обратной связи, которая интегрирована с Evi Sales.
                </v-list-item>
              </v-menu>
            </template>
            <div class="d-flex align-center">
              <v-radio
                  v-for="item in newClientsFormNotifications"
                  :label="item.name"
                  :value="item.value"
                  :key="item.value"
                  class="mb-0 mr-6 evi-radio"
                  color="#45BF6A"
              />
            </div>
          </v-radio-group>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "IntegrationSettingsModal",
  data: () => ({
    integrationsClientsDistribution: false,
    integrationsUsersViewAllChats: false,
    notificationsFromForm: false,
    clientsDistributionTypes: [
      {
        name: 'Сотрудники сами забирают клиентов',
        value: false,
      },
      {
        name: 'Автоматическое распределение по тегам',
        value: true,
      },
    ],
    usersViewChatTypes: [
      {
        name: 'Сотрудники видят только свои диалоги',
        value: false,
      },
      {
        name: 'Сотрудники видят все диалоги в интеграции',
        value: true,
      },
    ],
    newClientsFormNotifications: [
      {
        name: 'Включены',
        value: true,
      },
      {
        name: 'Выключены',
        value: false,
      },
    ]
  }),
  computed: {
    ...mapState('integrations', ['settings']),
  },
  methods: {
    ...mapActions({
      setSettings: 'integrations/setIntegrationsSettings',
    }),
    closeDialog () {
      this.$emit('close');
    },
    init (val) {
      this.integrationsClientsDistribution = val.integrations_clients_distribution;
      this.integrationsUsersViewAllChats = val.integrations_users_view_all_chats;
      this.notificationsFromForm = val.integrations_form_new_client_notification;
    },
    save () {
      this.setSettings({
        integrations_clients_distribution: this.integrationsClientsDistribution,
        integrations_users_view_all_chats: this.integrationsUsersViewAllChats,
        integrations_form_new_client_notification: this.notificationsFromForm,
      });
    }
  },
  mounted() {
    this.init(this.settings);
  },
  watch: {
    settings (val) {
      this.init(val);
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
