import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_GROUP_SCRIPTS, SET_SCRIPT_GROUPS, SET_SCRIPTS} from "../../mt";
import {hasRight} from '@/utils/access/hasRight';
import store  from '@/store';

export default {
    getAllScripts (context) {
        if (hasRight('scripts.view'))
            return AxiosWrapper.getWithoutLoading(`/api/scripts`).then((response) => {
                context.commit(SET_SCRIPTS, response.data.response);
                return response.data.response;
            });
    },
    getScriptsByGroup (context, payload) {
        if (hasRight('scripts.view'))
            return AxiosWrapper.getWithoutLoading(`/api/script-groups/${payload.id}/scripts`, {queryParams: {...payload.pagination}}).then((response) => {
                context.commit(SET_GROUP_SCRIPTS, response.data.response.data);
                store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
                return response.data.response.data;
            });
    },
    removeGroupScripts (context) {
        context.commit('REMOVE_GROUP_SCRIPTS');
    },
    getScriptGroups (context) {
        if (hasRight('scripts.view'))
            return AxiosWrapper.get(`/api/script-groups`).then((response) => {
                response.data.response.sort(function (a, b) {
                    return b.id - a.id;
                });
                context.commit(SET_SCRIPT_GROUPS, response.data.response);
                return response.data.response;
            });
    },
    createScriptGroup (context) {
        let payload = {name: `Группа фраз ${context.state.scriptGroups.length + 1}`};
        return AxiosWrapper.post(`/api/script-groups`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Группа фраз "${payload.name}" сохранена`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteScriptGroup (context, payload) {
        return AxiosWrapper.delete(`/api/script-groups/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Группа фраз "${payload.name}" удалена`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getSelectedScriptGroup (context, payload) {
        return AxiosWrapper.get(`/api/script-groups/${payload}`).then((response) => {
            return response.data.response;
        });
    },
    saveSelectedScriptGroup (context, payload) {
        return AxiosWrapper.put(`/api/script-groups/${payload.id}`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Группа фраз "${payload.name}" сохранена`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data.response;
        });
    },
    createScript (context, payload) {
        return AxiosWrapper.post(`/api/scripts`, payload).then((response) => {
            context.commit('ADD_SCRIPT', response.data.response);
            context.commit('ADD_SCRIPT_TO_GROUP', response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Быстрая фраза "${payload.name}" сохранена`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editScript (context, payload) {
        if (payload.text)
            return AxiosWrapper.put(`/api/scripts/${payload.id}`, payload).then((response) => {
                context.commit('CHANGE_SCRIPT', payload);
                return response;
            });
    },
    deleteScript (context, payload) {
        return AxiosWrapper.delete(`/api/scripts/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Быстрая фраза "${payload.name}" удалена`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getScriptsSearch(context, payload) {
        if (hasRight('scripts.view'))
            return AxiosWrapper.getWithoutLoading(`/api/scripts/search/`, {queryParams: {...payload}}).then((response) => {
                return response.data.response;
            });
    },
    addFile (context, payload) {
        return AxiosWrapper.post(`/api/scripts/${payload.id}/attachments`, payload.formData).then((response) => {
            context.commit('CHANGE_GROUP_SCRIPT', response.data.response);
            context.commit('CHANGE_SCRIPT', response.data.response);
            return response;
        });
    },
    deleteFile (context, payload) {
        return AxiosWrapper.delete(`/api/scripts/${payload.id}/attachments/${payload.fileId}`, ).then((response) => {
            context.commit('CHANGE_GROUP_SCRIPT', response.data.response);
            context.commit('CHANGE_SCRIPT', response.data.response);
            return response;
        });
    },
    uploadData (context, payload) {
        return AxiosWrapper.post(`/api/scripts/import`, payload).then((response) => {
            return response;
        });
    },
    downloadData (context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/scripts/export`, {queryParams: payload}, { responseType: 'blob' }).then((response) => {
            return response;
        });
    },
};
