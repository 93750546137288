import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_RIGHTS, SET_ROLES} from "../../mt";
import {hasRight} from '@/utils/access/hasRight';

export default {
    getRoles (context) {
        if (hasRight('permissions.view'))
            return AxiosWrapper.get(`/api/roles`).then((response) => {
                response.data.response.sort(function (a, b) {
                    return b.id - a.id;
                });
                context.commit(SET_ROLES, response.data.response);
                return response;
            });
    },
    createRole (context) {
        let payload = {name: `Роль ${context.state.roles.length + 1}`, color: '#99AAB5'};
        return AxiosWrapper.post(`/api/roles`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Роль "${payload.name}" сохранена`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteRole (context, payload) {
        return AxiosWrapper.delete(`/api/roles/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Роль "${payload.name}" удалена`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getSelectedRole (context, payload) {
        return AxiosWrapper.get(`/api/roles/${payload}`).then((response) => {
            return response.data.response;
        });
    },
    saveSelectedRole (context, payload) {
        return AxiosWrapper.put(`/api/roles/${payload.id}`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Роль "${payload.name}" сохранена`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            context.commit('CHANGE_SELECTED_ROLE', response.data.response);
            return response.data.response;
        });
    },
    getPermissions (context) {
        return AxiosWrapper.get(`/api/permissions`).then((response) => {
            context.commit(SET_RIGHTS, response.data.response);
            return response;
        });
    }
};
