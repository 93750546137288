import {SET_ANALYTICS, SET_ANALYTICS_ORGANIZATION} from "../../mt";

export default {
    [SET_ANALYTICS](state, payload) {
        state.analytics = payload;
    },
    [SET_ANALYTICS_ORGANIZATION](state, payload) {
        state.organization = payload;
    },
    ['SET_USER_ID'](state, payload) {
        state.userId = payload;
    }
};
