import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';


// service
// import NotFound from '../pages/404.vue';

// modules
// import StandardModuleRoutes from '@/modules/StandardModule/routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            name: 'Homepage',
            path: '/',
            component: () => import('@/pages/Home.vue'),
            redirect: { name: 'Tasks' },
            meta: {
                requiresAuth: true,
            },
            children: [
                //profile
                {
                    name: 'Profile',
                    path: 'profile',
                    component: () => import('@/pages/profile/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Профиль',
                        permission: 'any',
                    },
                },
                //tasks
                {
                    name: 'Tasks',
                    path: 'tasks',
                    component: () => import('@/pages/tasks/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Задачи',
                        permission: 'tasks',
                    },
                },
                {
                    name: 'Task-Page',
                    path: 'tasks/:id',
                    component: () => import('@/pages/tasks/TaskPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Задача',
                        permission: 'tasks',
                    },
                },
                //analytics
                {
                    name: 'Analytics',
                    path: 'analytics',
                    component: () => import('@/pages/analytics/Index.vue'),
                    redirect: { name: 'EmployeeAnalytics' },
                    meta: {
                        requiresAuth: true,
                        title: 'Аналитика',
                        permission: 'analytics',
                    },
                    children: [
                        {
                            name: 'OrganizationAnalytics',
                            path: 'organization',
                            component: () => import('@/pages/analytics/OrganizationAnalytics.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Аналитика по организации',
                                permission: 'analytics.organization',
                            },
                        },
                        {
                            name: 'EmployeeAnalytics',
                            path: 'users',
                            component: () => import('@/pages/analytics/EmployeeAnalytics.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Аналитика по сотрудникам',
                                permission: 'analytics.users',
                            },
                        },
                        {
                            name: 'SubdivisionAnalytics',
                            path: 'subdivision',
                            component: () => import('@/pages/analytics/SubdivisionAnalytics.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Аналитика по подразделениям',
                                permission: 'analytics.subdivisions',
                            },
                        },
                        {
                            name: 'SalesChannelAnalytics',
                            path: 'sales-channel',
                            component: () => import('@/pages/analytics/SalesChannelAnalytics.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Аналитика по каналам продаж',
                                permission: 'analytics.saleChannels',
                            },
                        },
                        {
                            name: 'PaymentReportsAnalytics',
                            path: 'payment-reports',
                            component: () => import('@/pages/analytics/PaymentReportsAnalytics.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Отчеты по оплате',
                                permission: 'analytics.payments',
                            },
                        },
                    ]
                },
                // deals
                {
                    name: 'Deals',
                    path: 'deals',
                    component: () => import('@/pages/deals/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Сделки',
                        permission: 'deals',
                        settings: 'deals.settings'
                    },
                },
                {
                    name: 'Deal-Page',
                    path: 'deals/:id',
                    component: () => import('@/pages/deals/DealPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Сделка',
                        permission: 'deals',
                        settings: 'deals.settings'
                    },
                },
                // clients
                {
                    name: 'Clients',
                    path: 'clients',
                    component: () => import('@/pages/clients/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Клиенты',
                        permission: 'clients',
                    },
                },
                {
                    name: 'Client-Page',
                    path: 'clients/:id',
                    component: () => import('@/pages/clients/ClientPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Клиент',
                        permission: 'clients',
                    },
                },
                // products
                {
                    name: 'Products',
                    path: 'products',
                    component: () => import('@/pages/products/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Продукты',
                        permission: 'products.view',
                    },
                },
                {
                    name: 'Product-Page',
                    path: 'products/:id',
                    component: () => import('@/pages/products/ProductPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Продукты',
                        permission: 'products.view',
                    },
                },
                // bills
                {
                    name: 'Счета',
                    path: 'bills',
                    component: () => import('@/pages/bills/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Счета',
                        permission: 'bills',
                    },
                },
                {
                    name: 'Счета',
                    path: 'bills/:id',
                    component: () => import('@/pages/bills/BillPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Счёт',
                        permission: 'bills',
                    },
                },
                // chat
                {
                    name: 'Chat',
                    path: 'chat',
                    component: () => import('@/pages/chat/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Чаты',
                        permission: 'any',
                        settings: 'chat.settings'
                    },
                    children: [
                        {
                            name: 'ChatUsers',
                            path: 'users/:id',
                            component: () => import('@/pages/chat/ChatWrapper.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Чат с сотрудником',
                                permission: 'any',
                                settings: 'chat.settings'
                            },
                        },
                        {
                            name: 'ChatClients',
                            path: 'clients/:id',
                            component: () => import('@/pages/chat/ChatWrapper.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Чат с клиентом',
                                permission: 'any',
                                settings: 'chat.settings'
                            },
                        },
                    ]
                },
                // mail
                {
                    name: 'Почта',
                    path: 'mail',
                    component: () => import('@/pages/mail/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Почта',
                        permission: 'mail',
                        hasLocalSettings: true,
                        settings: 'mail.settings',
                    },
                    children: [
                        {
                            name: 'MailPage',
                            path: ':mailId',
                            component: () => import('@/pages/mail/MailPage.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Почта',
                                permission: 'mail',
                                hasLocalSettings: true,
                                settings: 'mail.settings',
                            },
                            children: [
                                {
                                    name: 'MailFolders',
                                    path: ':folder',
                                    component: () => import('@/pages/mail/MailFolder.vue'),
                                    meta: {
                                        requiresAuth: true,
                                        title: 'Почта',
                                        permission: 'mail',
                                        hasLocalSettings: true,
                                        settings: 'mail.settings',
                                    },
                                    children: [
                                        {
                                            name: 'MessagePage',
                                            path: ':message',
                                            component: () => import('@/pages/mail/MessagePage.vue'),
                                            meta: {
                                                requiresAuth: true,
                                                title: 'Почта',
                                                permission: 'mail',
                                                hasLocalSettings: true,
                                                settings: 'mail.settings',
                                            },
                                        },
                                    ],
                                },
                            ],
                        }
                    ]
                },
                // mailers
                {
                    name: 'Mailers',
                    path: 'mailers',
                    component: () => import('@/pages/mailers/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Рассылки',
                        permission: 'mailers.view',
                    },
                },
                {
                    name: 'Mailer-Page',
                    path: 'mailers/:id',
                    component: () => import('@/pages/mailers/MailerPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Рассылки',
                        permission: 'mailers.view',
                    },
                },
                //users
                {
                    name: 'Users',
                    path: 'users',
                    component: () => import('@/pages/users/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Сотрудники',
                        permission: 'users.view',
                    },
                },
                {
                    name: 'User-Page',
                    path: 'users/:id',
                    component: () => import('@/pages/users/UserPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Сотрудник',
                        permission: 'users.view',
                    },
                },
                //subdivisions
                {
                    name: 'Subdivisions',
                    path: 'subdivisions',
                    component: () => import('@/pages/subdivisions/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Подразделения',
                        permission: 'subdivisions.view',
                    },
                },
                {
                    name: 'Subdivision-Page',
                    path: 'subdivisions/:id',
                    component: () => import('@/pages/subdivisions/SubdivisionPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Подразделение',
                        permission: 'subdivisions.view',
                    },
                },
                //event-log
                {
                    name: 'EventLog',
                    path: 'event-log',
                    component: () => import('@/pages/event-log/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Журнал событий',
                        permission: 'activitylog',
                    },
                },
                //integrations
                {
                    name: 'Integrations',
                    path: 'integrations',
                    component: () => import('@/pages/integrations/MainPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Интеграции',
                        permission: 'integrations.view',
                        settings: 'integrations.settings'
                    },
                },
                {
                    name: 'IntegrationsType',
                    path: 'integrations/type/:id',
                    component: () => import('@/pages/integrations/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Интеграции',
                        permission: 'integrations.view',
                        settings: 'integrations.settings'
                    },
                },
                {
                    name: 'Integration-Page',
                    path: 'integrations/:id',
                    component: () => import('@/pages/integrations/IntegrationPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Интеграция',
                        permission: 'integrations.view',
                        settings: 'integrations.settings'
                    },
                },
                {
                    name: 'Integration-Form-Page',
                    path: 'integrations/forms/:id',
                    component: () => import('@/pages/integrations/IntegrationFormPage.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Интеграция',
                        permission: 'integrations.view',
                        settings: 'integrations.settings'
                    },
                },
                //scripts
                {
                    name: 'Scripts',
                    path: 'scripts',
                    component: () => import('@/pages/scripts/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Быстрые фразы',
                        permission: 'scripts.view',
                    },
                },
                //Автоматизация
                {
                    name: 'Automations',
                    path: 'automations',
                    component: () => import('@/pages/automations/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Автоматизация',
                        permission: 'automations.view',
                    },
                },
                //permissions
                {
                    name: 'Permissions',
                    path: 'permissions',
                    component: () => import('@/pages/permissions/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Роли и права',
                        permission: 'permissions.view',
                    },
                },
                //deal statuses
                {
                    name: 'DealStatuses',
                    path: 'deal-statuses',
                    component: () => import('@/pages/deal-statuses/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Статусы сделок',
                        permission: 'dealStatuses.view',
                    },
                },
                //client statuses
                {
                    name: 'ClientStatuses',
                    path: 'client-statuses',
                    component: () => import('@/pages/client-statuses/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Статусы клиентов',
                        permission: 'clientStatuses',
                    },
                },
                //client tags
                {
                    name: 'Tags',
                    path: 'tags',
                    component: () => import('@/pages/tags/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Теги',
                        permission: 'tags',
                    },
                },
                //payments
                {
                    name: 'Payments',
                    path: 'payments',
                    component: () => import('@/pages/payments/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Тарифы и оплата',
                        permission: 'payment.access',
                    },
                },
                //feedback
                {
                    name: 'Feedback',
                    path: 'feedback',
                    component: () => import('@/pages/feedback/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Обратная связь',
                        permission: 'any',
                    },
                },
            ],
        },
        //auth
        {
            name: 'auth',
            path: '/login',
            component: () => import('@/pages/auth/Index.vue'),
            meta: {
                layout: 'clean',
                title: 'Авторизация'
            },
        },
        {
            name: 'password-change',
            path: '/password-change',
            component: () => import('@/pages/password-change/Index.vue'),
            meta: {
                layout: 'clean',
                title: 'Восстановление пароля'
            },
        },

        //service
        {
            name: 'NotFound',
            path: '*',
            component: () => import('@/pages/NotFound.vue'),
            props: true,
            meta: {
                layout: 'main',
                title: 'Страница не найдена'
            },
        },
        {
            name: 'NoAccess',
            path: '/no-access',
            component: () => import('@/pages/NoAccess.vue'),
            props: true,
            meta: {
                layout: 'main',
                title: 'Нет доступа'
            },
        },
    ],
    scrollBehavior (to, from, savedPosition) {
        let position = { x: 0, y: 0 }
        if (savedPosition) {
            position = savedPosition
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(position)
            }, 300)
        })
    }
});

// для авторизации
router.beforeEach((to, from, next) => {
    let permissions = [];
    if (store.state.user.accessToken) {
        if (store.state.user.profile.roles.length) {
            for (let i in store.state.user.profile.roles) {
                if (Object.prototype.hasOwnProperty.call(store.state.user.profile.roles, i)) {
                    permissions = permissions.concat(store.state.user.profile.roles[i].permissions);
                }
            }
        }
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.state.user.accessToken) {
            if (
                permissions && permissions.some((a) => a.name.includes(to.meta.permission))
                || to.meta.permission === 'any'
            ) {
                next();
            } else {
                next({ name: 'NoAccess' });
            }
        } else {
            next('/login');
        }
        return;
    }
    next();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        if (to && to.meta && to.meta.title) {
            document.title = `${to.meta.title} - Evi Sales`;
        } else {
            document.title = `Evi Sales`;
        }
    });
});

export default router;
