<template>
  <v-card class="modal-create" v-if="data">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">{{data.title}}</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="close">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-col
            cols="12"
        >
          <p class="mb-7">
            Для импорта ваш файл должен подходить под шаблон, с которым вы можете ознакомиться, скачав его:
          </p>
          <div class="mb-7">
            <a download :href="`https://evisales.com/docs/import/templates/${data.template}.xlsx`" class="template-download-button evi-button-green mr-2">
              <img class="mr-2" width="23" :src="require('@/assets/img/common/export.svg')" alt="">
              Шаблон .xlsx
            </a>
          </div>
          <p v-if="data.template !== 'scripts'">
            Все поля в шаблоне должны присутствовать в вашем файле, но вы также можете
            добавить свои поля в файл, они автоматически создадутся в добавленных полях.
          </p>
          <p>
            Если ваш импортируемый файл уже подстроен под шаблон, то его можно загрузить в поле ниже.
          </p>
          <v-file-input
              accept=".xlsx, .csv"
              show-size
              label="Выберите файл для импорта"
              class="evi-text-field"
              v-model="doc"
              :rules="fileRules"
              color="#44D370"
          />
          <small class="ml-8">Принимаются документы с расширением .xlsx, .csv</small>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="uploadData">
          Импортировать
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "ImportModal",
  props: {
    data: {
      type: Object,
    },
  },
  data: () => ({
    doc: null,
    valid: true,
    fileRules: [(v) => !!v || 'Выберите файл'],
  }),
  methods: {
    validate () {
      return !!this.$refs.form.validate();
    },
    async uploadData () {
      if (this.validate()) {
        let formData = new FormData();
        formData.append('file', this.doc);
        await this.$emit('uploadData', formData);
        this.doc = null;
        this.$emit('close');
        this.$refs.form.resetValidation();
      }
    },
    close () {
      this.doc = null;
      this.$emit('close');
      this.$refs.form.resetValidation();
    }
  },
}
</script>

<style lang="scss" scoped>
.template-download-button {
  padding: 0 16px;
  height: 36px;
  width: 164px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
</style>
