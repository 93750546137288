import {ADD_ALERT, REMOVE_ALERT, SET_LOADING, SET_MENU, SET_TABLE_META, START_REQUEST, STOP_REQUEST} from "../../mt";

export default {
    [SET_MENU](state, payload) {
        state.menu = payload;
    },
    [ADD_ALERT]: function (state, { timeout, ...alert }) {
        alert.timeout = timeout || 30000;
        alert.id = new Date().getTime();
        state.alerts.unshift(alert);
        this.dispatch('common/removeAlertCounter', alert);
    },

    [REMOVE_ALERT]: (state, id) => {
        for (let i = 0; i < state.alerts.length; i++) {
            if (state.alerts[i].id === id) {
                state.alerts.splice(i, 1);
                break;
            }
        }
    },

    [START_REQUEST]: (state, uuid) => {
        state.requests.push(uuid);
    },
    [STOP_REQUEST]: (state, uuid) => {
        state.requests = state.requests.filter((item) => item !== uuid);
    },

    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },

    [SET_TABLE_META](state, payload) {
        state.tableMeta = payload;
    },
};
