import { AxiosWrapper } from '@/api/AxiosWrapper';
import {
    SET_CDEK_CITIES,
    SET_CDEK_TARIFFS,
    SET_DEAL,
    SET_DEAL_CUSTOM_FIELDS,
    SET_DEALS,
    SET_CDEK_OFFICES, SET_DEAL_SETTINGS
} from "../../mt";
import store  from '@/store';
import {hasRight} from '@/utils/access/hasRight';

export default {
    getDeals (context) {
        if (hasRight('deals.viewAll') || hasRight('deals.viewOwn'))
            return AxiosWrapper.get(`/api/deals`).then((response) => {
                context.commit(SET_DEALS, response.data.response);
                return response;
            });
    },
    getDealsBy (context, payload) {
        if (hasRight('deals.viewAll') || hasRight('deals.viewOwn'))
            return AxiosWrapper.getWithoutLoading(`/api/deals`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_DEALS, response.data.response.data);
                return response.data.response;
            });
    },
    addDealsBy(context, payload) {
        if (hasRight('deals.viewAll') || hasRight('deals.viewOwn'))
            return AxiosWrapper.getWithoutLoading(`/api/deals`, {queryParams: {...payload}}).then((response) => {
                context.commit('ADD_DEALS', response.data.response.data);
                return response.data.response;
            });
    },
    getDealsPages (context, payload) {
        if (hasRight('deals.viewAll') || hasRight('deals.viewOwn'))
            return AxiosWrapper.get(`/api/deals`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_DEALS, response.data.response.data);
                store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
                return response;
            });
    },
    resetDeals (context) {
        context.commit(SET_DEALS, []);
    },
    getDealsPagesWithoutLoading (context, payload) {
        if (hasRight('deals.viewAll') || hasRight('deals.viewOwn'))
            return AxiosWrapper.getWithoutLoading(`/api/deals`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_DEALS, response.data.response.data);
                store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
                return response;
            });
    },
    getDeal (context, payload) {
        if (hasRight('deals.viewAll') || hasRight('deals.viewOwn'))
            return AxiosWrapper.get(`/api/deals/${payload}`).then((response) => {
                let i = 0;
                response.data.response.comments.forEach(item => item.index = i++);
                response.data.response.comments.sort(function (a, b) {
                    return b.index - a.index;
                });
                context.commit(SET_DEAL, response.data.response);
                return response;
            });
    },
    getDealWithoutLoading (context, payload) {
        if (hasRight('deals.viewAll') || hasRight('deals.viewOwn'))
            return AxiosWrapper.getWithoutLoading(`/api/deals/${payload}`).then((response) => {
                let i = 0;
                response.data.response.comments.forEach(item => item.index = i++);
                response.data.response.comments.sort(function (a, b) {
                    return b.index - a.index;
                });
                context.commit(SET_DEAL, response.data.response);
                return response;
            });
    },
    clearDeal (context) {
        context.commit(SET_DEAL, {});
    },
    editDeal (context, payload) {
        return AxiosWrapper.put(`/api/deals/${payload.id}`, payload).then((response) => {
            let i = 0;
            response.data.response.comments.forEach(item => item.index = i++);
            response.data.response.comments.sort(function (a, b) {
                return b.index - a.index;
            });
            context.commit(SET_DEAL, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editDealDelivery (context, payload) {
        return AxiosWrapper.post(`/api/deals/${payload.id}/delivery`, payload).then((response) => {
            let i = 0;
            response.data.response.comments.forEach(item => item.index = i++);
            response.data.response.comments.sort(function (a, b) {
                return b.index - a.index;
            });
            context.commit(SET_DEAL, response.data.response);
            return response;
        });
    },
    editDealWithoutLoading (context, payload) {
        return AxiosWrapper.putWithoutLoading(`/api/deals/${payload.id}`, payload).then((response) => {
            context.commit(SET_DEAL, response.data.response);
            return response;
        });
    },
    createDeal (context, payload) {
        return AxiosWrapper.post(`/api/deals`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Сделка успешно добавлена!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteDeal (context, payload) {
        return AxiosWrapper.delete(`/api/deals/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Сделка была удалена`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    createComment (context, payload) {
        return AxiosWrapper.post(`/api/deals/${payload.id}/comments`, {comment: payload.comment}).then((response) => {
            let i = 0;
            response.data.response.comments.forEach(item => item.index = i++);
            response.data.response.comments.sort(function (a, b) {
                return b.index - a.index;
            });
            context.commit(SET_DEAL, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Комментарий был отправлен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteComment (context, payload) {
        return AxiosWrapper.delete(`/api/deals/${payload.id}/comments/${payload.commentId}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Комментарий был удален`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    addProduct (context, payload) {
        return AxiosWrapper.post(`/api/deals/${payload.dealId}/products`, payload.data).then((response) => {
            context.commit(SET_DEAL, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Продукт добавлен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editProduct (context, payload) {
        return AxiosWrapper.put(`/api/deals/${payload.dealId}/products`, payload.data).then((response) => {
            context.commit(SET_DEAL, response.data.response);
            return response;
        });
    },
    deleteProduct (context, payload) {
        return AxiosWrapper.delete(`/api/deals/${payload.dealId}/products`, {data: payload.data}).then((response) => {
            context.commit(SET_DEAL, response.data.response);
            return response;
        });
    },
    uploadDeals (context, payload) {
        if (payload.from) {
            return AxiosWrapper.post(`/api/deals/import/${payload.from}`, payload.file).then((response) => {
                return response;
            });
        }
        return AxiosWrapper.post(`/api/deals/import`, payload).then((response) => {
            return response;
        });
    },
    downloadDeals (context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/deals`, {queryParams: payload}, { responseType: 'blob' }).then((response) => {
            return response;
        });
    },
    getDealCustomFields (context) {
        return AxiosWrapper.getWithoutLoading(`/api/deals/custom-fields`).then((response) => {
            context.commit(SET_DEAL_CUSTOM_FIELDS, response.data.response);
            return response;
        });
    },
    getDealCustomFieldsForScripts (context) {
        return AxiosWrapper.getWithoutLoading(`/api/deals/custom-fields`).then((response) => {
            context.commit(SET_DEAL_CUSTOM_FIELDS, response.data.response);
            context.commit('scripts/SET_DEAL_CUSTOM_FIELDS_SCRIPTS', response.data.response, { root: true })
            return response;
        });
    },
    createDealCustomFields (context, payload) {
        return AxiosWrapper.post(`/api/deals/custom-fields`, payload).then((response) => {
            context.commit('ADD_CUSTOM_FIELD', response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Поле было добавлено`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editDealCustomFields (context, payload) {
        return AxiosWrapper.putWithoutLoading(`/api/deals/custom-fields/${payload.id}`, payload).then((response) => {
            context.commit('SET_CUSTOM_FIELD', response.data.response);
            return response;
        });
    },
    deleteDealCustomFields (context, payload) {
        context.commit('REMOVE_CUSTOM_FIELD', payload.index);
        return AxiosWrapper.delete(`/api/deals/custom-fields/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Поле было удалено`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getCdekTariffs (context) {
        return AxiosWrapper.getWithoutLoading(`/api/integrations/cdek/tariffs`).then((response) => {
            context.commit(SET_CDEK_TARIFFS, response.data.response);
            return response.data.response;
        });
    },
    getCdekCities (context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/integrations/cdek/cities`, {queryParams: {city: payload.city,}}, null).then((response) => {
            context.commit(SET_CDEK_CITIES, response.data.response);
            return response.data.response;
        });
    },
    getCdekOffices (context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/integrations/cdek/offices`, {queryParams: {city_code: payload.city_code,}}, null).then((response) => {
            context.commit(SET_CDEK_OFFICES, response.data.response);
            return response.data.response;
        });
    },
    sendToCdek (context, payload) {
        return AxiosWrapper.post(`/api/deals/${payload}/delivery/cdek`).then((response) => {
            context.commit(SET_DEAL, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Данные отправлены в СДЭК. В течение некоторого времени, у доставки появится трек-номер`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getDealsSettings (context) {
        if (hasRight('deals.settings')) {
            return AxiosWrapper.getWithoutLoading(`/api/settings/deals`).then((response) => {
                context.commit(SET_DEAL_SETTINGS, response.data.response);
                return response.data.response;
            });
        }
    },
    setDealsSettings (context, payload) {
        return AxiosWrapper.post(`/api/settings/deals`, payload).then((response) => {
            context.commit(SET_DEAL_SETTINGS, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data.response;
        });
    },
};
