import {AxiosWrapper} from '@/api/AxiosWrapper';
import store  from '@/store';
import {SET_BILL, SET_BILLS} from "../../mt";

export default {
    getBillsPages (context, payload) {
        return AxiosWrapper.get(`/api/bills`, {queryParams: {...payload}}).then((response) => {
            context.commit(SET_BILLS, response.data.response.data);
            store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
            return response;
        });
    },
    editBillsConfigure (context, payload) {
        return AxiosWrapper.post(`/api/bills/configure`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Реквизиты сохранены!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getBillsConfigure () {
        return AxiosWrapper.getWithoutLoading(`/api/bills/configure`).then((response) => {
            return response.data.response;
        });
    },
    createBill (context, payload) {
        return AxiosWrapper.post(`/api/bills`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Счёт создан!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getBill (context, payload) {
        return AxiosWrapper.get(`/api/bills/${payload}`).then((response) => {
            context.commit(SET_BILL, response.data.response);
            return response.data.response;
        });
    },
    editBill (context, payload) {
        return AxiosWrapper.put(`/api/bills/${payload.id}`, payload).then((response) => {
            context.commit(SET_BILL, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data.response;
        });
    },
    deleteBill (context, payload) {
        return AxiosWrapper.delete(`/api/bills/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Счёт был удален`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
};
