import { AxiosWrapper } from '@/api/AxiosWrapper';
import axios from 'axios';
import {SET_ACCESS_TOKEN, SET_PROFILE, SET_TENANT, SET_WEBSOCKET_DATA} from "../../mt";
import router from '../../../router';

export default {
    login (context, payload) {
        return AxiosWrapper.post(`/api/login`, payload).then((response) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.response.access_token;
            context.commit(SET_PROFILE, response.data.response.profile);
            context.commit(SET_ACCESS_TOKEN, response.data.response.access_token);
            context.commit(SET_TENANT, response.data.response.tenant);
            context.commit(SET_WEBSOCKET_DATA, response.data.response.websockets);
            return response;
        });
    },
    restorePassword (context, payload) {
        return AxiosWrapper.post(`/api/reset-password`, payload).then((response) => {
            return response;
        });
    },
    logOut(context) {
        context.commit(SET_ACCESS_TOKEN, null);
        context.commit(SET_PROFILE, {});
        context.commit(SET_TENANT, {});
        context.commit(SET_WEBSOCKET_DATA, {});
        localStorage.removeItem('evi-sales');
        router.push({path:'/login'});
    },
    getProfile (context) {
        return AxiosWrapper.get(`/api/profile`).then((response) => {
            context.commit(SET_PROFILE, response.data.response);
            return response.data.response;
        });
    },
    getProfileWithoutLoading (context) {
        return AxiosWrapper.getWithoutLoading(`/api/profile`).then((response) => {
            context.commit(SET_PROFILE, response.data.response);
            return response.data.response;
        });
    },
    editProfile (context, payload) {
        return AxiosWrapper.put(`/api/profile`, payload).then((response) => {
            context.commit(SET_PROFILE, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editProfileAvatar (context, payload) {
        return AxiosWrapper.post(`/api/profile`, payload).then((response) => {
            context.commit(SET_PROFILE, response.data.response);
            return response;
        });
    },
    changePassword (context, payload) {
        return AxiosWrapper.post(`/api/profile/change-password`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Пароль был успешно сменен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    changeMainUser (context, payload) {
        return AxiosWrapper.post(`/api/profile/change-main-user`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Владелец был успешно сменен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
};
