import {SET_TAGS} from "../../mt";

export default {
    [SET_TAGS](state, payload) {
        state.tags = payload;
    },
    ['SET_TAGS_CHANGES'](state, payload) {
        for (let i = 0; i < state.tags.length; i++) {
            if (state.tags[i].id === payload.id) {
                state.tags.splice(i, 1, payload);
                break;
            }
        }
    },
};
