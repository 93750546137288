import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_EVENTS} from "../../mt";
import {hasRight} from '@/utils/access/hasRight';
import store from "@/store";

export default {
    getItems (context) {
        if (hasRight('activitylog.viewAll') || hasRight('activitylog.viewSubordinate') || hasRight('activitylog.viewOwn'))
            return AxiosWrapper.get(`/api/activity-logs`).then((response) => {
                context.commit(SET_EVENTS, response.data.response);
                return response;
            });
    },
    getItemsPages (context, payload) {
        if (hasRight('activitylog.viewAll') || hasRight('activitylog.viewSubordinate') || hasRight('activitylog.viewOwn'))
            return AxiosWrapper.get(`/api/activity-logs`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_EVENTS, response.data.response.data);
                store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
                return response;
            });
    },
};
