export default {
    automations: [],
    autoTasks: [
        {
            title: 'Закрепление сотрудника за клиентом',
            value: 'client_responsible_user_set',
        },
        {
            title: 'Установление статуса клиенту',
            value: 'client_status_change',
        },
        {
            title: 'Напоминание в чате',
            value: 'reminder_create',
        },
        {
            title: 'Добавление тега',
            value: 'client_tag_add',
        },
        {
            title: 'Удаление тега',
            value: 'client_tag_remove',
        },
    ],
};
