import {SET_NOTIFICATIONS, SET_NOTIFICATIONS_COUNT} from "../../mt";

export default {
    [SET_NOTIFICATIONS](state, payload) {
        state.notifications = payload;
    },
    ['SET_NOTIFICATIONS_LOCAL'](state, payload) {
        state.notifications.unshift(payload);
    },
    [SET_NOTIFICATIONS_COUNT](state, payload) {
        state.count = payload;
    },
    ['SET_NOTIFICATIONS_COUNT_LOCAL'](state) {
        state.count++;
    },
};
