import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_TASK, SET_TASKS} from "../../mt";

export default {
    getTasks (context) {
        return AxiosWrapper.get(`/api/tasks`).then((response) => {
            context.commit(SET_TASKS, response.data.response);
            return response;
        });
    },
    getTask (context, payload) {
        return AxiosWrapper.get(`/api/tasks/${payload}`).then((response) => {
            context.commit(SET_TASK, response.data.response);
            return response;
        });
    },
    editTask (context, payload) {
        return AxiosWrapper.putWithoutLoading(`/api/tasks/${payload.id}`, payload).then((response) => {
            context.commit(SET_TASK, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    createTask (context, payload) {
        return AxiosWrapper.post(`/api/tasks`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Задача успешно создана!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteTask (context, payload) {
        return AxiosWrapper.delete(`/api/tasks/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Задача была удалена`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    doneTask (context, payload) {
        return AxiosWrapper.post(`/api/tasks/${payload.id}/done`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Задача была завершена`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    addFile (context, payload) {
        return AxiosWrapper.post(`/api/tasks/${payload.id}/attachments`, payload.formData).then((response) => {
            context.commit(SET_TASK, response.data.response);
            return response;
        });
    },
    deleteFile (context, payload) {
        return AxiosWrapper.delete(`/api/tasks/${payload.id}/attachments/${payload.fileId}`, ).then((response) => {
            return response;
        });
    },
    createComment (context, payload) {
        return AxiosWrapper.post(`/api/tasks/${payload.id}/comments`, {comment: payload.comment}).then((response) => {
            let i = 0;
            response.data.response.comments.forEach(item => item.index = i++);
            response.data.response.comments.sort(function (a, b) {
                return b.index - a.index;
            });

            context.commit(SET_TASK, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Комментарий был отправлен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteComment (context, payload) {
        return AxiosWrapper.delete(`/api/tasks/${payload.id}/comments/${payload.commentId}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Комментарий был удален`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
};
