<template>
  <v-card class="modal-create settings">
    <v-form ref="form">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Общие настройки чатов</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-radio-group
              v-model="forwardMessageAuthorInfo"
              class="evi-radio-button-group"
          >
            <template v-slot:label>
              Указать автора пересланного сообщения в соц.сети
              <v-menu
                  top
                  offset-y
                  open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  При включенной настройке, будет указан в тексте
                  <br>
                   пересланного сообщения в скобках автор
                </v-list-item>
              </v-menu>
            </template>
            <div class="d-flex align-center">
              <v-radio
                  v-for="item in radios"
                  :label="item.name"
                  :value="item.value"
                  :key="item.value"
                  class="mb-0 mr-6 evi-radio"
                  color="#45BF6A"
              />
            </div>
          </v-radio-group>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "ChatSettingsModal",
  data: () => ({
    forwardMessageAuthorInfo: false,
    radios: [
      {
        name: 'Включено',
        value: true,
      },
      {
        name: 'Выключено',
        value: false,
      },
    ],
  }),
  computed: {
    ...mapState('chat', ['settings']),
  },
  methods: {
    ...mapActions({
      getSettings: 'chat/getChatSettings',
      setSettings: 'chat/setChatSettings',
    }),
    closeDialog () {
      this.$emit('close');
    },
    save () {
      this.setSettings({
        chats_add_additional_info_to_forwarded_messages: this.forwardMessageAuthorInfo,
      });
    }
  },
  mounted() {
    this.getSettings();
  },
  watch: {
    settings (val) {
      if (val) {
        this.forwardMessageAuthorInfo = val.chats_add_additional_info_to_forwarded_messages
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
