<template>
  <v-menu offset-y :close-on-content-click="false" v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-on="on"
          v-bind="attrs"
          icon
          class="evi-button-badge"
      >
        <v-badge
            color="#FF6553"
            :content="count"
            :value="count"
            offset-x="10"
            offset-y="9"
            v-if="isDark"
        >
          <img width="20" :src="require('@/assets/img/menu/bell-dark.svg')" alt=""/>
        </v-badge>
        <v-badge
            color="#FF6553"
            :content="count"
            :value="count"
            offset-x="10"
            offset-y="9"
            v-else
        >
          <img width="20" :src="require('@/assets/img/menu/bell.svg')" alt=""/>
        </v-badge>
      </v-btn>
    </template>
    <div class="notifications-dialog">
      <div class="notifications-dialog__header">
        <div class="d-flex">
          <h3 class="mr-3">Уведомления</h3>
          <v-menu
              top
              offset-y
              open-on-hover
              max-height="156"
          >
            <template v-slot:activator="{ on, attrs }">
              <img v-bind="attrs" v-on="on" class="pointer" v-if="areNotifyMuted" @click="mute"
                   :src="require('@/assets/img/chat/chats-not-muted.svg')" alt="">
              <img v-bind="attrs" v-on="on" class="pointer" v-else @click="mute"
                   :src="require('@/assets/img/chat/chats-muted.svg')" alt="">
            </template>
            <v-list-item v-if="areNotifyMuted" class="user-color-status d-flex justify-space-between align-center">
              Звуковые оповещения выключены
            </v-list-item>
            <v-list-item v-else class="user-color-status d-flex justify-space-between align-center">
              Звуковые оповещения включены
            </v-list-item>
          </v-menu>
        </div>
        <div class="notifications-dialog__read-all" @click="readAll" v-if="notifications.length && hasUnreadNotifi">
          Отметить все как прочитанное
        </div>
        <div class="notifications-dialog__empty" v-else>
          Нет новых уведомлений
        </div>
      </div>
      <div class="notifications-dialog__body">
        <div class="notifications-dialog__item" v-for="item in notifications" :key="item.id"
             @click="openItemPage(item)">
          <img width="36" :src="require(`@/assets/img/notifications/${item.data.type}.svg`)" v-if="item.data.type"
               alt=""/>
          <img width="36" :src="require('@/assets/img/notifications/task.svg')" v-else alt=""/>
          <div class="d-flex flex-column">
            <div class="notifications-dialog__item-name">
              {{ item.data.message }}
            </div>
            <div class="notifications-dialog__item-date">
              {{ item.created_at | moment('DD.MM.YYYY [в] HH:mm') }}
            </div>
          </div>
          <span class="notifications-dialog__unread" v-if="!item.read_at"/>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "NotificationsDialog",
  props: {
    count: {
      type: Number,
    },
    isDark: {
      type: Boolean,
    },
    notifications: {
      type: Array,
    }
  },
  data: () => ({
    menu: false,
    areNotifyMuted: false,
  }),
  computed: {
    hasUnreadNotifi() {
      return this.notifications.some(i => !i.read_at);
    }
  },
  methods: {
    ...mapActions({
      markNotifiIsRead: 'notifications/markNotifiIsRead',
    }),
    mute () {
      this.areNotifyMuted = !this.areNotifyMuted;
    },
    readAll() {
      let array = [];
      this.notifications.forEach(i => {
        array.push(i.id);
      });
      this.markNotifiIsRead(array).then((response) => {
        if (response) {
          this.$emit('update');
        }
      })
    },
    openItemPage(item) {
      this.markNotifiIsRead([item.id]).then((response) => {
        if (response) {
          this.$emit('update');
        }
      });
      this.menu = false;

      if (item.data && item.data.model_id) {
        if (item.data.type === "task") {
          this.$router.push({path: `/tasks/${item.data.model_id}`}).catch(() => {});
        } else if (item.data.type === "forms") {
          this.$router.push({path: `/clients/${item.data.model_id}`}).catch(() => {});
        } else if (item.data.type === "mailer") {
          this.$router.push({path: `/mailers/${item.data.model_id}`}).catch(() => {});
        } else {
          this.$router.push({path: `/payments`}).catch(() => {
          });
        }
      }
    }
  },
  mounted() {
    this.areNotifyMuted = localStorage.getItem("are_notifications_muted") === "true";
  },
  watch: {
    areNotifyMuted(v) {
      localStorage.setItem("are_notifications_muted", v.toString());
    },
  }
}
</script>

<style lang="scss" scoped>
.notifications-dialog {
  background: #FFFFFF;
  box-shadow: 10px -2px 54px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  width: 487px;
  min-width: 487px;
  padding: 37px 0;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 30px;

    h3 {
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      color: $evi-headline-color;
    }
  }

  &__body {
    max-height: 476px;
    overflow-y: scroll;
    padding: 0 30px;
  }

  &__read-all {
    color: $evi-green-color;
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  &__item {
    cursor: pointer;
    padding: 23px 0;
    border-top: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
    position: relative;

    img {
      margin-right: 14px;
    }

    &-name {
      font-weight: normal;
      font-size: 14px;
      color: $evi-headline-color;
      max-width: 340px;
    }

    &-date {
      font-weight: normal;
      font-size: 14px;
      color: $evi-grey-color;
    }
  }

  &__unread {
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    right: 0;
    background: $evi-green-color;
  }

  &__empty {
    font-weight: 600;
    font-size: 14px;
    color: #5C5C5C;
    height: 100%;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    background: transparent;
  }
}
</style>
