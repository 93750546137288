import {
    SET_DEAL_CUSTOM_FIELDS,
    SET_DEAL,
    SET_DEALS,
    SET_CDEK_TARIFFS,
    SET_CDEK_CITIES, SET_CDEK_OFFICES, SET_DEAL_SETTINGS
} from "../../mt";

export default {
    [SET_DEALS](state, payload) {
        state.deals = payload;
    },
    ['ADD_DEALS'](state, payload) {
        payload.forEach(i => {
            if (!state.deals.some(e => e.id === i.id)) {
                state.deals.push(i);
            }
        });
    },
    [SET_DEAL](state, payload) {
        state.deal = payload;
    },
    [SET_CDEK_TARIFFS](state, payload) {
        state.cdekTariffs = payload;
    },
    [SET_CDEK_CITIES](state, payload) {
        state.cdekCities = payload;
    },
    [SET_CDEK_OFFICES](state, payload) {
        state.cdekOffices = payload;
    },
    [SET_DEAL_CUSTOM_FIELDS](state, payload) {
        state.dealCustomFields = payload;
    },
    ['ADD_CUSTOM_FIELD'](state, payload) {
        state.dealCustomFields.push(payload);
    },
    ['SET_CUSTOM_FIELD'](state, payload) {
        for (const item of state.dealCustomFields) {
            if (item.id === payload.id) {
                item.name = payload.name;
                item.type = payload.type;
                item.values = payload.values;
                break;
            }
        }
    },
    ['REMOVE_CUSTOM_FIELD'](state, payload) {
        state.dealCustomFields.splice(payload, 1);
    },
    [SET_DEAL_SETTINGS](state, payload) {
        state.settings = payload;
    }
};
