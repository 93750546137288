import {AxiosWrapper} from '@/api/AxiosWrapper';
import {SET_ANALYTICS, SET_ANALYTICS_ORGANIZATION} from "../../mt";
import store  from '@/store';

export default {
    getOrganizationAnalytics(context, payload) {
        return AxiosWrapper.get(`/api/reports/organization`, {queryParams: {...payload}}).then((response) => {
            context.commit(SET_ANALYTICS_ORGANIZATION, response.data.response[0]);
            return response.data.response;
        });
    },
    getUserAnalytics(context, payload) {
        return AxiosWrapper.get(`/api/reports/users`, {queryParams: {...payload}}).then((response) => {
            response.data.response.data.forEach(i => {
                i.user = {
                    name: i.name,
                    avatar_url: i.avatar_url,
                };
            });
            context.commit(SET_ANALYTICS, response.data.response.data);
            let tableMeta = {
                current_page: response.data.response.current_page,
                from: response.data.response.from,
                last_page: response.data.response.last_page,
                per_page: response.data.response.per_page,
                to: response.data.response.to,
                total: response.data.response.total,
            };

            store.commit('common/' + 'SET_TABLE_META', tableMeta);
            return response.data.response.data;
        });
    },
    getSubdivisionAnalytics(context, payload) {
        return AxiosWrapper.get(`/api/reports/subdivisions`, {queryParams: {...payload}}).then((response) => {
            response.data.response.data.forEach(i => {
                i.subdivision = {
                    name: i.name,
                    id: i.id,
                };
            });
            context.commit(SET_ANALYTICS, response.data.response.data);
            let tableMeta = {
                current_page: response.data.response.current_page,
                from: response.data.response.from,
                last_page: response.data.response.last_page,
                per_page: response.data.response.per_page,
                to: response.data.response.to,
                total: response.data.response.total,
            };

            store.commit('common/' + 'SET_TABLE_META', tableMeta);
            return response.data.response.data;
        });
    },
    getIntegrationsAnalytics(context, payload) {
        return AxiosWrapper.get(`/api/reports/sale-channels`, {queryParams: {...payload}}).then((response) => {
            response.data.response.data.forEach(i => {
                i.int = {
                    name: i.name,
                    type: i.type,
                };
            });
            context.commit(SET_ANALYTICS, response.data.response.data);
            let tableMeta = {
                current_page: response.data.response.current_page,
                from: response.data.response.from,
                last_page: response.data.response.last_page,
                per_page: response.data.response.per_page,
                to: response.data.response.to,
                total: response.data.response.total,
            };

            store.commit('common/' + 'SET_TABLE_META', tableMeta);
            return response.data.response.data;
        });
    },
    getPaymentsAnalytics(context, payload) {
        return AxiosWrapper.get(`/api/reports/payments`, {queryParams: {...payload}} ).then((response) => {
            context.commit(SET_ANALYTICS, response.data.response.data);

            let tableMeta = {
                current_page: response.data.response.current_page,
                from: response.data.response.from,
                last_page: response.data.response.last_page,
                per_page: response.data.response.per_page,
                to: response.data.response.to,
                total: response.data.response.total,
            };

            store.commit('common/' + 'SET_TABLE_META', tableMeta);
            return response.data.response.data;
        });
    },
    downloadReport(context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/reports/${payload.name}`, {queryParams: {...payload.args}}, { responseType: 'blob' }).then((response) => {
            return response;
        });
    },
    setUserId(context, payload) {
        context.commit('SET_USER_ID', payload);
    }
};
