import axios from 'axios';
import store  from '@/store';
import qs from 'qs';
import uuid from 'uuid';
import {SET_LOADING, START_REQUEST, STOP_REQUEST} from '../store/mt';

const api = process.env.VUE_APP_API_URL;
const commonApi = process.env.VUE_APP_API_COMMON_URL;

const logout = () => {
  store.dispatch('user/logOut');
};

function addAlert(payload) {
  store.commit('common/' + 'ADD_ALERT', payload);
}

export class AxiosWrapper {
  static get axios() {
    return axios;
  }

  static __getPath(path) {
    return [api, path.replace(/\//, '')].join('/');
  }

  static __getCommonPath(path) {
    return [commonApi, path.replace(/\//, '')].join('/');
  }

  static async getWithoutLoading(path, data, config) {
    const id = uuid();
    store.commit('common/' + START_REQUEST, id);

    const response = axios.get(
        `${this.__getPath(path)}` +
        formPathParams(data ? data.pathParams : null) +
        formQueryParams(data ? data.queryParams : null),
        config
    );

    response.then(request(id), error(id));

    return response;
  }

  static async putWithoutLoading(path, data, config) {
    const id = uuid();
    store.commit('common/' + START_REQUEST, id);

    const response = axios.put(`${this.__getPath(path)}`, data, config);
    response.then(request(id), error(id));

    return response;
  }

  static async get(path, data, config) {
    const id = uuid();
    store.commit('common/' + START_REQUEST, id);
    store.commit('common/' + SET_LOADING, true);

    const response = axios.get(
      `${this.__getPath(path)}` +
        formPathParams(data ? data.pathParams : null) +
        formQueryParams(data ? data.queryParams : null),
      config
    );

    response.then(request(id), error(id));

    return response;
  }

  static async post(path, data, config) {
    const id = uuid();
    store.commit('common/' + START_REQUEST, id);

    const response = axios.post(`${this.__getPath(path)}`, data, config);
    response.then(request(id), error(id));

    return response;
  }

  static async put(path, data, config) {
    const id = uuid();
    store.commit('common/' + START_REQUEST, id);
    // store.commit('common/' + SET_LOADING, true);

    const response = axios.put(`${this.__getPath(path)}`, data, config);
    response.then(request(id), error(id));

    return response;
  }

  static async delete(path, data, config) {
    const id = uuid();
    store.commit('common/' + START_REQUEST, id);

    const response = axios.delete(`${this.__getPath(path)}`, data, config);
    response.then(request(id), error(id));

    return response;
  }

  static async commonPost(path, data, config) {
    const id = uuid();
    store.commit('common/' + START_REQUEST, id);

    const response = axios.post(`${this.__getCommonPath(path)}`, data, config);
    response.then(request(id), error(id));

    return response;
  }

  static query(string) {
    return qs.stringify(string);
  }
}

const request = function (id) {
  return function (response) {
    store.commit('common/' + STOP_REQUEST, id);
    store.commit('common/' + SET_LOADING, false);

    status.handler(response.status, response);
    if (response.data.message) {
      addAlert({
        text: response.data.message,
        type: response.data.success ? 'success' : 'error',
      });
    }
  };
};

const error = function (id) {
  return function (error) {
    store.commit('common/' + STOP_REQUEST, id);
    status.handler(error.response.status, error.response);
  };
};

const status = {
  500: (response) => {
    let message = 'Произошла ошибка на стороне сервера';
    addAlert({
      text: response.data.message || message,
      type: 'error',
    });
  },
  403: (response) => {
    let message = response.data.message;
    if (message) {
      addAlert({
        text: message,
        type: 'error',
      });
    }
  },
  401: () => {
    logout();
  },
  400: (response) => {
    let message = 'Произошла ошибка на стороне сервера';
    addAlert({
      text: response.data.message || message,
      type: 'error',
    });
  },
  error: (response) => {
    if (response.data.errors) {
      const keys = Object.keys(response.data.errors);
      for (let i of keys) {
        for (let m of response.data.errors[i]) {
          addAlert({
            text: `${m}`,
            type: 'error',
          });
        }
      }
    }
  },
  default: () => {},
  handler: function (code = 'default') {
    let statusFunction = this[code] ? this[code] : code >= 400 ? this.error : this.default;
    return statusFunction.apply(this, [].slice.call(arguments, 1));
  },
};

function formQueryParams(params) {
  let queryParams = '';
  if (params) {
    queryParams = '?' + qs.stringify(params || {}, { arrayFormat: 'bracket' });
  }
  return queryParams;
}

function formPathParams(params) {
  let pathParams = '';
  if (params) {
    for (let [key] of Object.entries(params)) {
      pathParams += `/${params[key]}`;
    }
  }
  return pathParams;
}
