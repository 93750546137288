import { AxiosWrapper } from '@/api/AxiosWrapper';
import {ADD_CLIENT, SET_CLIENT, SET_CLIENT_CUSTOM_FIELDS, SET_CLIENTS,} from "../../mt";
import store  from '@/store';
import {hasRight} from '@/utils/access/hasRight';

export default {
    getClients (context) {
        if (hasRight('clients.viewOwn') || hasRight('clients.viewAll'))
            return AxiosWrapper.get(`/api/clients`).then((response) => {
                context.commit(SET_CLIENTS, response.data.response);
                return response;
            });
    },
    getClientBy (context, payload) {
        if (hasRight('clients.viewOwn') || hasRight('clients.viewAll'))
            return AxiosWrapper.getWithoutLoading(`/api/clients`, {queryParams: {...payload}}).then((response) => {
                response.data.response.data.forEach(i => {
                    i.client_name = i.name;
                });
                context.commit(SET_CLIENTS, response.data.response.data);
                return response.data.response;
            });
    },
    addClientBy (context, payload) {
        if (hasRight('clients.viewOwn') || hasRight('clients.viewAll'))
            return AxiosWrapper.getWithoutLoading(`/api/clients`, {queryParams: {...payload}}).then((response) => {
                response.data.response.data.forEach(i => {
                    i.client_name = i.name;
                });
                context.commit('SET_CLIENTS_BY', response.data.response.data);
                return response.data.response;
            });
    },
    getClientsPages (context, payload) {
        if (hasRight('clients.viewOwn') || hasRight('clients.viewAll'))
            return AxiosWrapper.get(`/api/clients`, {queryParams: {...payload}}).then((response) => {
                response.data.response.data.forEach(i => {
                    i.client_name = i.name;
                });
                context.commit(SET_CLIENTS, response.data.response.data);
                store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
                return response;
            });
    },
    getClient (context, payload) {
        if (hasRight('clients.viewOwn') || hasRight('clients.viewAll'))
            return AxiosWrapper.get(`/api/clients/${payload}`).then((response) => {
                let i = 0;
                response.data.response.comments.forEach(item => item.index = i++);
                response.data.response.comments.sort(function (a, b) {
                    return b.index - a.index;
                });
                context.commit(SET_CLIENT, response.data.response);
                return response;
            });
    },
    editClient (context, payload) {
        return AxiosWrapper.putWithoutLoading(`/api/clients/${payload.id}`, payload).then((response) => {
            let i = 0;
            response.data.response.comments.forEach(item => item.index = i++);
            response.data.response.comments.sort(function (a, b) {
                return b.index - a.index;
            });
            context.commit(SET_CLIENT, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editClientWithoutLoading (context, payload) {
        return AxiosWrapper.putWithoutLoading(`/api/clients/${payload.id}`, payload).then((response) => {
            return response;
        });
    },
    createClient (context, payload) {
        return AxiosWrapper.post(`/api/clients`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Клиент "${payload.name}" успешно добавлен!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteClient (context, payload) {
        return AxiosWrapper.delete(`/api/clients/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Клиент "${payload.name}" был удален`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    addClient(context, payload) {
      return context.commit(ADD_CLIENT, payload);
    },
    createComment (context, payload) {
        return AxiosWrapper.post(`/api/clients/${payload.id}/comments`, {comment: payload.comment}).then((response) => {
            let i = 0;
            response.data.response.comments.forEach(item => item.index = i++);
            response.data.response.comments.sort(function (a, b) {
                return b.index - a.index;
            });

            context.commit(SET_CLIENT, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Комментарий был отправлен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );

            return response;
        });
    },
    deleteComment (context, payload) {
        return AxiosWrapper.delete(`/api/clients/${payload.id}/comments/${payload.commentId}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Комментарий был удален`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getClientCustomFields (context) {
        return AxiosWrapper.getWithoutLoading(`/api/clients/custom-fields`).then((response) => {
            context.commit(SET_CLIENT_CUSTOM_FIELDS, response.data.response);
            return response;
        });
    },
    getClientCustomFieldsForScripts (context) {
        return AxiosWrapper.getWithoutLoading(`/api/clients/custom-fields`).then((response) => {
            context.commit(SET_CLIENT_CUSTOM_FIELDS, response.data.response);
            context.commit('scripts/SET_CLIENT_CUSTOM_FIELDS_SCRIPTS', response.data.response, { root: true })
            return response;
        });
    },
    createClientCustomFields (context, payload) {
        return AxiosWrapper.post(`/api/clients/custom-fields`, payload).then((response) => {
            context.commit('ADD_CUSTOM_FIELD', response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Поле было добавлено`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editClientCustomFields (context, payload) {
        return AxiosWrapper.putWithoutLoading(`/api/clients/custom-fields/${payload.id}`, payload).then((response) => {
            context.commit('SET_CUSTOM_FIELD', response.data.response);
            return response;
        });
    },
    deleteClientCustomFields (context, payload) {
        context.commit('REMOVE_CUSTOM_FIELD', payload.index);
        return AxiosWrapper.delete(`/api/clients/custom-fields/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Поле было удалено`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    uploadClients (context, payload) {
        return AxiosWrapper.post(`/api/clients/import`, payload).then((response) => {
            return response;
        });
    },
    downloadClients (context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/clients`, {queryParams: payload}, { responseType: 'blob' }).then((response) => {
            return response;
        });
    },
};
