import {SET_AUTOMATIONS} from "../../mt";

export default {
    [SET_AUTOMATIONS](state, payload) {
        state.automations = payload;
    },
    ['SET_AUTOMATION'](state, payload) {
        state.automations.forEach((item, index) => {
            if (item.id === payload.id) {
                state.automations.splice(index, 1, payload);
            }
        });
    },
};
