import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import VueMask from 'v-mask';
import DatetimePicker from 'vuetify-datetime-picker';
import VueParticles from 'vue-particles';
import './sass/main.scss';
import vueKanban from 'vue-kanban';

Vue.config.productionTip = false;

export const eventBus = new Vue();

//components
import MainLayout from './layouts/MainLayout';
import CleanLayout from './layouts/CleanLayout';
import EsTable from "./components/сommon/EsTable/EsTable";

//components enabled
Vue.component('es-table', EsTable);

// layouts
Vue.component('main-layout', MainLayout);
Vue.component('clean-layout', CleanLayout);

// use
const moment = require('moment');
require('moment/locale/ru');
Vue.use(require('vue-moment'), {
    moment,
});
Vue.use(VueMask);
Vue.use(DatetimePicker);
Vue.use(VueParticles);
Vue.use(vueKanban);


new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
}).$mount('#app')
