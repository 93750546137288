export default {
    products: [],
    types: [
        {
            name: 'Товар',
            id: 1,
            value: 'product'
        },
        {
            name: 'Услуга',
            id: 2,
            value: 'service'
        },
    ],
    product: {},
};
