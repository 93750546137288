export default {
    chats: [],
    chatsForward: [],
    chat: {},
    chatInfo: {},
    messages: [],
    chatType: '',
    chatsUnreadCount: 0,
    reminders: {
        today: [],
        tomorrow: [],
        future: [],
        outdated: [],
    },
    settings: {}
};
