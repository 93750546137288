import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_TAGS} from "../../mt";
import {hasRight} from '@/utils/access/hasRight';

export default {
    getItems (context) {
        if (hasRight('tags.view'))
            return AxiosWrapper.get(`/api/tags`).then((response) => {
                response.data.response.sort(function (a, b) {
                    return b.id - a.id;
                });
                context.commit(SET_TAGS, response.data.response);
                return response;
            });
    },
    getItemsBy (context) {
        if (hasRight('tags.view'))
            return AxiosWrapper.getWithoutLoading(`/api/tags`).then((response) => {
                response.data.response.sort(function (a, b) {
                    return b.id - a.id;
                });
                context.commit(SET_TAGS, response.data.response);
                return response;
            });
    },
    createItem (context) {
        let payload = {name: `Тег ${context.state.tags.length}`, text_color: '#E9E9E9',  color: '#99AAB5', stage: 'active'};
        return AxiosWrapper.post(`/api/tags`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Тег "${payload.name}" сохранен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteItem (context, payload) {
        return AxiosWrapper.delete(`/api/tags/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Тег "${payload.name}" удален`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getSelectedItem (context, payload) {
        return AxiosWrapper.get(`/api/tags/${payload}`).then((response) => {
            return response.data.response;
        });
    },
    saveSelectedItem (context, payload) {
        return AxiosWrapper.put(`/api/tags/${payload.id}`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Тег "${payload.name}" сохранен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            context.commit('SET_TAGS_CHANGES', response.data.response);
            return response.data.response;
        });
    },
};
