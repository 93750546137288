<template>
    <!--content goes here -->
    <v-main>
        <slot />
    </v-main>

    <!--content goes here -->
</template>

<script>
    export default {
        name: "CleanLayout"
    }
</script>

<style scoped>

</style>
