import {pluralize} from "../tools/Helpers";

export var TimeAnswerMixin = {
    methods: {
        returnTimeAnswer(time) {
            let hours = Math.floor(time / 60 / 60);
            let minutes = Math.floor(time / 60) - (hours * 60);

            let secondsPluralize = ['секунда', 'секунды', 'секунд'];
            let minutesPluralize = ['минута', 'минуты', 'минут'];
            let hoursPluralize = ['час', 'часа', 'часов'];

            if (time < 60) {
                return pluralize(time, secondsPluralize);
            }
            else if (time > 60 && time < 3600) {
                return pluralize(minutes, minutesPluralize);
            } else {
                return pluralize(hours, hoursPluralize);
            }
        }
    }
};
