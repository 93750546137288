<template>
  <div class="list-alerts">
    <v-slide-x-reverse-transition group>
      <v-alert
        v-for="alert in alerts"
        :key="alert.id"
        :type="alert.type"
        elevation="3"
        prominent
        @input="closeAlert(alert.id)"
      >
        <div class="click-wrapper" @click="closeAlert(alert.id)"></div>
        <v-row align="center">
          <v-col class="grow">{{ alert.text }}</v-col>
          <v-col class="shrink">
            <v-btn color="white" fab small text @click="closeAlert(alert.id)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ListAlerts',
  computed: {
    ...mapState('common', ['alerts']),
  },
  methods: {
    closeAlert(id) {
      this.$store.commit('common/REMOVE_ALERT', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-alerts {
  position: fixed;
  top: 10px;
  right: 5px;
  display: block;
  z-index: 999999;
}

.v-alert {
  padding: 10px;
}

.click-wrapper {
  width: 100%;
  position: absolute;
  height: 70px;
  top: 0;
  left: 0;
}

.slide-x-reverse-transition-enter,
.slide-x-reverse-transition-leave-to {
  -webkit-transform: translateX(100%);
  opacity: 0;
  transform: translateX(100%);
}

.slide-x-reverse-transition-enter-active {
  transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.slide-x-reverse-transition-leave-active {
  transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
