export default {
    bills: [],
    bill: {},
    ndsItems: [
        {
            name: 'Без НДС',
            value: 0,
            id: 1,
        },
        {
            name: '0%',
            value: 0,
            id: 2,
        },
        {
            name: '10%',
            value: 10,
            id: 3,
        },
        {
            name: '20%',
            value: 20,
            id: 4,
        },
    ],
};
