import {SET_PRODUCT, SET_PRODUCTS} from "../../mt";

export default {
    [SET_PRODUCTS](state, payload) {
        state.products = payload;
    },
    ['ADD_PRODUCTS'](state, payload) {
        payload.forEach(i => {
            if (!state.products.some(e => e.id === i.id)) {
                state.products.push(i);
            }
        });
    },
    [SET_PRODUCT](state, payload) {
        state.product = payload;
    },
};
