const allModuleFileNames = require.context('.', true, /\.js$/);

const modules = {};

allModuleFileNames.keys().forEach((fileName) => {
  if (fileName === './module.js') return;
  if (fileName === './index.js') return;

  const [moduleName, moduleElement] = fileName.replace(/(\.\/|\.js)/g, '').split('/');

  // eslint-disable-next-line no-prototype-builtins
  if (!modules.hasOwnProperty(moduleName)) {
    modules[moduleName] = {
      namespaced: true,
    };
  }

  if (moduleElement === 'index') {
    modules[moduleName] = allModuleFileNames(fileName).default;
    return;
  }

  modules[moduleName][moduleElement] = allModuleFileNames(fileName).default;
});

export default modules;
