import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_SUBDIVISION, SET_SUBDIVISIONS, SET_USER, SET_USERS} from "../../mt";
import store  from '@/store';
import {hasRight} from '@/utils/access/hasRight';

export default {
    getUsers (context) {
        if (hasRight('users.viewAll') || hasRight('users.viewSubordinate'))
            return AxiosWrapper.get(`/api/users`).then((response) => {
                context.commit(SET_USERS, response.data.response);
                return response;
            });
    },
    getUsersBy (context, payload) {
        if (hasRight('users.viewAll') || hasRight('users.view') || hasRight('users.viewSubordinate'))
            return AxiosWrapper.getWithoutLoading(`/api/users`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_USERS, response.data.response.data);
                return response.data.response;
            });
    },
    addUsersBy(context, payload) {
        if (hasRight('users.viewAll') || hasRight('users.view') || hasRight('users.viewSubordinate'))
            return AxiosWrapper.getWithoutLoading(`/api/users`, {queryParams: {...payload}}).then((response) => {
                context.commit('ADD_USERS', response.data.response.data);
                return response.data.response;
            });
    },
    getUsersPages (context, payload) {
        if (hasRight('users.viewAll') || hasRight('users.view') || hasRight('users.viewSubordinate'))
            return AxiosWrapper.get(`/api/users`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_USERS, response.data.response.data);
                store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
                return response;
            });
    },
    inviteUser (context, payload) {
        return AxiosWrapper.post(`/api/users`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Приглашение отправлено на ${payload.email}`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    fireUser (context, payload) {
        return AxiosWrapper.post(`/api/users/${payload.id}/fire`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `${payload.name} был уволен`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    restoreUser (context, payload) {
        return AxiosWrapper.post(`/api/users/${payload.id}/restore`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `${payload.name} был восстановлен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getUser (context, payload) {
        if (hasRight('users.viewAll') || hasRight('users.view') || hasRight('users.viewSubordinate'))
            return AxiosWrapper.get(`/api/users/${payload}`).then((response) => {
                context.commit(SET_USER, response.data.response);
                return response;
            });
    },
    editUser (context, payload) {
        return AxiosWrapper.put(`/api/users/${payload.id}`, payload).then((response) => {
            context.commit(SET_USER, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getSubdivisions (context) {
        if (hasRight('subdivisions.view'))
            return AxiosWrapper.get(`/api/subdivisions`).then((response) => {
                context.commit(SET_SUBDIVISIONS, response.data.response);
                return response;
            });
    },
    getSubdivisionsBy (context, payload) {
        if (hasRight('subdivisions.view'))
            return AxiosWrapper.getWithoutLoading(`/api/subdivisions`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_SUBDIVISIONS, response.data.response.data);
                return response.data.response;
            });
    },
    addSubdivisionsBy (context, payload) {
        if (hasRight('subdivisions.view'))
            return AxiosWrapper.getWithoutLoading(`/api/subdivisions`, {queryParams: {...payload}}).then((response) => {
                context.commit('ADD_SUBDIVISIONS', response.data.response.data);
                return response.data.response;
            });
    },
    getSubdivisionsPages (context, payload) {
        if (hasRight('subdivisions.view'))
            return AxiosWrapper.get(`/api/subdivisions`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_SUBDIVISIONS, response.data.response.data);
                store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
                return response;
            });
    },
    getSubdivision (context, payload) {
        return AxiosWrapper.get(`/api/subdivisions/${payload}`).then((response) => {
            context.commit(SET_SUBDIVISION, response.data.response);
            return response;
        });
    },
    editSubdivision (context, payload) {
        return AxiosWrapper.put(`/api/subdivisions/${payload.id}`, payload).then((response) => {
            context.commit(SET_SUBDIVISION, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    createSubdivision (context, payload) {
        return AxiosWrapper.post(`/api/subdivisions`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Подразделение "${payload.name}" успешно создано!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteSubdivision (context, payload) {
        return AxiosWrapper.delete(`/api/subdivisions/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Подразделение ${payload.name} было удалено`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
};
