import {SET_SUBDIVISION, SET_SUBDIVISIONS, SET_USER, SET_USERS} from "../../mt";

export default {
    [SET_USERS](state, payload) {
        state.users = payload;
    },
    ['ADD_USERS'](state, payload) {
        payload.forEach(i => {
            if (!state.users.some(e => e.id === i.id)) {
                state.users.push(i);
            }
        });
    },
    [SET_USER](state, payload) {
        state.user = payload;
    },
    [SET_SUBDIVISIONS](state, payload) {
        state.subdivisions = payload;
    },
    ['ADD_SUBDIVISIONS'](state, payload) {
        payload.forEach(i => {
            if (!state.subdivisions.some(e => e.id === i.id)) {
                state.subdivisions.push(i);
            }
        });
    },
    [SET_SUBDIVISION](state, payload) {
        state.subdivision = payload;
    },
};
