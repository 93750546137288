export default {
    integrations: [],
    integration: {},
    settings: {},
    integrationTypes: [
        {
            name: "ВКонтакте",
            value: "vk",
            color: '#0077FF',
            iconTypeWidth: 53,
            marginIconTop: '0px',
            marginIconBottom: '12px',
            showInClients: true,
        },
        // {
        //     name: "Instagram",
        //     value: "instagram",
        //     color: '#F00176',
        //     iconTypeWidth: 53,
        //     marginIconTop: '0px',
        //     marginIconBottom: '12px',
        //     showInClients: true,
        // },
        {
            name: "Telegram",
            value: "telegram",
            color: '#2FA5E1',
            iconTypeWidth: 53,
            marginIconTop: '0px',
            marginIconBottom: '12px',
            showInClients: true,
        },
        {
            name: "Форма на сайт",
            value: "form",
            color: '#FFB800',
            iconTypeWidth: 53,
            marginIconTop: '0px',
            marginIconBottom: '12px',
            showInClients: true,
        },
        {
            name: "СДЭК",
            value: "cdek",
            color: '#00B33C',
            iconTypeWidth: 106,
            marginIconTop: '15px',
            marginIconBottom: '18px',
            showInClients: false,
            information: `Для работы со СДЭК вам необходимо заключить договор с компанией СДЭК. \n В параметрах СДЭК организация должна быть указана как "интернет-магазин".`,
        },
        {
            name: "Почта",
            value: "mail",
            color: '#EA4335',
            iconTypeWidth: 53,
            marginIconTop: '0px',
            marginIconBottom: '12px',
            showInClients: true,
            isBeta: true,
        },
    ],
    extremists: ['instagram',],
};
