var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"es-table"},[_vm._t("default"),_c('EsFiltration',{attrs:{"showByOptions":_vm.showByOptions,"itemsLength":_vm.tableMeta.total,"showBy":30,"filter":_vm.filter,"dateFilter":_vm.dateFilter,"download":_vm.download,"upload":_vm.upload,"sortable":_vm.sortable,"importData":_vm.importData,"importFormats":_vm.importFormats,"exportFormats":_vm.exportFormats},on:{"updatePages":_vm.updatePages,"downloadData":_vm.downloadData,"uploadData":_vm.uploadData}},[_c('template',{slot:"sort-menu"},[_vm._t("sort-menu")],2)],2),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody","width":"100%","height":"100%"}}):_vm._e(),_vm._m(0),(!_vm.loading)?_c('v-data-table',{staticClass:"elevation-1 evi-table",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":_vm.tableMeta.per_page || _vm.showByOptions[1].counter},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":((_vm.$route.path) + "/" + (item.id))}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/users/" + (item.id))}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"#FFFFFF","size":"40"}},[_c('img',{attrs:{"src":item.user.avatar_url ||  require('@/assets/img/users/profile-avatar.png'),"alt":""}})]),_c('span',[_vm._v(" "+_vm._s(item.user.name)+" ")])],1):_vm._e()]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":item.client_id ? ("/clients/" + (item.client_id)) :("/clients/" + (item.id))}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"#FFFFFF","size":"40"}},[_c('img',{attrs:{"src":item.avatar_url || item.client_avatar_url || require('@/assets/img/users/profile-avatar.png'),"alt":""}})]),_c('div',{staticClass:"es-table__router-link-title"},[_vm._v(" "+_vm._s(item.client_name || item.name)+" "),(!item.read)?_c('span',{staticClass:"client-read"},[_vm._v("новый")]):_vm._e()])],1)]}},{key:"item.last_deal.executor.id",fn:function(ref){
var item = ref.item;
return [(item.last_deal)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/users/" + (item.last_deal.executor.id))}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"#FFFFFF","size":"40"}},[_c('img',{attrs:{"src":item.last_deal.executor.avatar_url || require('@/assets/img/users/profile-avatar.png'),"alt":""}})]),_c('span',[_vm._v(" "+_vm._s(item.last_deal.executor.name)+" ")])],1):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":((_vm.$route.path) + "/" + (item.id))}},[_c('span',[_vm._v(" "+_vm._s(item.id)+" ")])])]}},{key:"item.client.name",fn:function(ref){
var item = ref.item;
return [(item.client)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/clients/" + (item.client.id))}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"#FFFFFF","size":"40"}},[_c('img',{attrs:{"src":item.client.avatar_url ||  require('@/assets/img/users/profile-avatar.png'),"alt":""}})]),_c('span',[_vm._v(" "+_vm._s(item.client.name)+" ")])],1):_c('small',{staticClass:"grey--text"},[_vm._v(" Клиент был удален ")])]}},{key:"item.deal_status.name",fn:function(ref){
var item = ref.item;
return [(item.deal_status)?_c('div',{staticClass:"es-table__color-status",style:({color: ("" + (item.deal_status.color))})},[_vm._v(" "+_vm._s(item.deal_status.name)+" ")]):_vm._e(),_c('small',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.current_status_apply_date,"from", "now"))+" ")])]}},{key:"item.last_deal.deal_status.name",fn:function(ref){
var item = ref.item;
return [(item.last_deal && item.last_deal.deal_status)?_c('div',{staticClass:"es-table__color-status",style:({color: ("" + (item.last_deal.deal_status.color))})},[_vm._v(" "+_vm._s(item.last_deal.deal_status.name)+" ")]):_vm._e(),(item.last_deal)?_c('small',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.last_deal.current_status_apply_date,"from", "now"))+" ")]):_vm._e()]}},{key:"item.client_status",fn:function(ref){
var item = ref.item;
return [(item.client_status)?_c('div',{staticClass:"es-table__color-status",style:({color: ("" + (item.client_status.color))})},[_vm._v(" "+_vm._s(item.client_status.name)+" ")]):_vm._e()]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [(item.tags.length)?_c('div',{staticClass:"evi-autocomplete__tag mr-2 mt-2",style:({backgroundColor: ("" + (item.tags[0].color)), color: ("" + (item.tags[0].text_color)) })},[_vm._v(" "+_vm._s(item.tags[0].name)+" ")]):_vm._e(),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.tags.length > 1)?_c('div',_vm._g(_vm._b({staticClass:"evi-autocomplete__tag-more mt-1"},'div',attrs,false),on),[_vm._v("еще "+_vm._s(item.tags.length - 1)+" ")]):_vm._e()]}}],null,true)},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},_vm._l((item.tags),function(tag){return _c('div',{key:tag.id,staticClass:"evi-autocomplete__tag mr-2 mt-2",style:({backgroundColor: ("" + (tag.color)), color: ("" + (tag.text_color)) })},[_vm._v(" "+_vm._s(tag.name)+" ")])}),0)],1)]}},{key:"item.subdivision.name",fn:function(ref){
var item = ref.item;
return [(item.subdivision)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/subdivisions/" + (item.subdivision.id))}},[_c('span',[_vm._v(" "+_vm._s(item.subdivision.name)+" ")])]):_vm._e()]}},{key:"item.last_deal.name",fn:function(ref){
var item = ref.item;
return [(item.last_deal)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/deals/" + (item.last_deal.id))}},[_c('span',[_vm._v(" "+_vm._s(item.last_deal.name)+" ")])]):_vm._e()]}},{key:"item.deal.name",fn:function(ref){
var item = ref.item;
return [(item.deal)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/deals/" + (item.deal.id))}},[_c('span',[_vm._v(" "+_vm._s(item.deal.name)+" ")])]):_vm._e()]}},{key:"item.payment_deal_name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/deals/" + (item.id))}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]):_vm._e()]}},{key:"item.bill_number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/bills/" + (item.id))}},[_c('span',[_vm._v(" "+_vm._s(item.number)+" ")])])]}},{key:"item.conversion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number.isInteger(item.conversion) ? item.conversion : parseFloat(item.conversion).toFixed(2))+"% ")]}},{key:"item.int.name",fn:function(ref){
var item = ref.item;
return [(item.int)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"title":item.name,"to":("/integrations/" + (item.id))}},[(item.type)?_c('img',{staticClass:"mr-1",attrs:{"width":"25","src":require(("@/assets/img/integrations/" + (item.type) + ".svg")),"alt":""}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.int.name)+" ")])]):(item.integration && item.integration.type === 'form')?_c('router-link',{staticClass:"es-table__router-link",attrs:{"title":item.integration.name,"to":("/integrations/forms/" + (item.integration.id))}},[_c('img',{staticClass:"mr-1",attrs:{"width":"25","src":require("@/assets/img/integrations/form.svg"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(item.integration.name)+" ")])]):_vm._e()]}},{key:"item.integration.name",fn:function(ref){
var item = ref.item;
return [(item.integration && item.integration.type !== 'form')?_c('router-link',{staticClass:"es-table__router-link",attrs:{"title":item.integration.name,"to":("/integrations/" + (item.integration.id))}},[_c('img',{staticClass:"mr-1",attrs:{"width":"25","src":require(("@/assets/img/integrations/" + (item.integration.type) + ".svg")),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(item.integration.name)+" ")])]):(item.integration && item.integration.type === 'form')?_c('router-link',{staticClass:"es-table__router-link",attrs:{"title":item.integration.name,"to":("/integrations/forms/" + (item.integration.id))}},[_c('img',{staticClass:"mr-1",attrs:{"width":"25","src":require("@/assets/img/integrations/form.svg"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(item.integration.name)+" ")])]):_vm._e()]}},{key:"item.executor",fn:function(ref){
var item = ref.item;
return [(item.executor)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/users/" + (item.executor.id))}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"#FFFFFF","size":"40"}},[_c('img',{attrs:{"src":item.executor.avatar_url || require('@/assets/img/users/profile-avatar.png'),"alt":""}})]),_c('span',[_vm._v(" "+_vm._s(item.executor.name)+" ")])],1):_vm._e()]}},{key:"item.head",fn:function(ref){
var item = ref.item;
return [(item.head)?_c('router-link',{staticClass:"es-table__router-link",attrs:{"to":("/users/" + (item.head.id))}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"#FFFFFF","size":"40"}},[_c('img',{attrs:{"src":item.head.avatar_url || require('@/assets/img/users/profile-avatar.png'),"alt":""}})]),_c('span',[_vm._v(" "+_vm._s(item.head.name)+" ")])],1):_vm._e()]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.users),function(user){return _c('v-menu',{key:user.id,attrs:{"top":"","offset-y":"","max-height":"156","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":("/users/" + (user.id))}},[_c('v-avatar',_vm._g(_vm._b({staticClass:"user-page__avatar mr-2 mt-1 mb-1",attrs:{"color":"#FFFFFF","size":"40"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":user.avatar_url ||require('@/assets/img/users/profile-avatar.png'),"alt":""}})])],1)]}}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(user.name))])],1)}),1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("VMask")(item.price,_vm.priceMask))+" ")]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [(item.sum)?_c('div',[_vm._v(" "+_vm._s(_vm._f("VMask")(item.sum,_vm.priceMask))+" ")]):_vm._e()]}},{key:"item.sales_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("VMask")(item.sales_amount,_vm.priceMask))+" ")]}},{key:"item.monthly_plan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("VMask")(item.monthly_plan,_vm.priceMask))+" ")]}},{key:"item.average_check",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("VMask")(item.average_check,_vm.priceMask))+" ")]}},{key:"item.deal.sum",fn:function(ref){
var item = ref.item;
return [(item.deal)?_c('div',[_vm._v(" "+_vm._s(_vm._f("VMask")(item.deal.sum,_vm.priceMask))+" ")]):_vm._e()]}},{key:"item.is_regular",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_regular ? 'Регулярная' : 'Отложенная')+" ")]}},{key:"item.notify",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.notify ? 'Включены' : 'Выключены')+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'product')?_c('div',[_vm._v(" Товар ")]):(item.type === 'service')?_c('div',[_vm._v(" Услуга ")]):_vm._e()]}},{key:"item.monthly_plan_progress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.monthly_plan_progress)+"% ")]}},{key:"item.first_contact_date",fn:function(ref){
var item = ref.item;
return [(item.first_contact_date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.first_contact_date,'DD.MM.YYYY HH:mm'))+" ")]):_vm._e()]}},{key:"item.client.first_contact_date",fn:function(ref){
var item = ref.item;
return [(item.client && item.client.first_contact_date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.client.first_contact_date,'DD.MM.YYYY HH:mm'))+" ")]):_vm._e()]}},{key:"item.last_contact_date",fn:function(ref){
var item = ref.item;
return [(item.last_contact_date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.last_contact_date,'DD.MM.YYYY в HH:mm'))+" ")]):_vm._e()]}},{key:"item.client.last_contact_date",fn:function(ref){
var item = ref.item;
return [(item.client && item.client.last_contact_date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.client.last_contact_date,'DD.MM.YYYY HH:mm'))+" ")]):_vm._e()]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [(item.payment_date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.payment_date,'DD.MM.YYYY HH:mm'))+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [(item.created_at)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'DD.MM.YYYY HH:mm'))+" ")]):_vm._e()]}},{key:"item.bill_date",fn:function(ref){
var item = ref.item;
return [(item.bill_date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.bill_date,'DD.MM.YYYY'))+" ")]):_vm._e()]}},{key:"item.send_date",fn:function(ref){
var item = ref.item;
return [(item.send_date && !item.is_regular)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.send_date,'DD.MM.YYYY'))+" ")]):_c('div',{staticClass:"font-italic font-weight-light"},[_vm._v(" Указывается при отложенной рассылке ")])]}},{key:"item.days_of_week",fn:function(ref){
var item = ref.item;
return [(item.days_of_week.length)?_c('div',_vm._l((item.days_of_week),function(i,index){return _c('span',{key:i.id},[_vm._v(" "+_vm._s(_vm.dayOfWeek(i))),(index !== item.days_of_week.length -1)?_c('span',[_vm._v(",")]):_vm._e()])}),0):_c('div',{staticClass:"font-italic font-weight-light"},[_vm._v(" Указывается при регулярной рассылке ")])]}},{key:"item.first_message_answer_time",fn:function(ref){
var item = ref.item;
return [(item.first_message_answer_time)?_c('div',[_vm._v(" "+_vm._s(_vm.returnTimeAnswer(item.first_message_answer_time))+" ")]):_vm._e()]}},{key:"item.client.first_message_answer_time",fn:function(ref){
var item = ref.item;
return [(item.client && item.client.first_message_answer_time)?_c('div',[_vm._v(" "+_vm._s(_vm.returnTimeAnswer(item.client.first_message_answer_time))+" ")]):_vm._e()]}},{key:"item.last_comment.comment",fn:function(ref){
var item = ref.item;
return [(item.last_comment)?_c('div',{staticClass:"last-comment",attrs:{"title":item.last_comment.comment}},[_vm._v(" "+_vm._s(item.last_comment.comment)+" ")]):_vm._e()]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.returnNds(item.tax).name)+" ")]}},{key:"item.activity_time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"es-table__color-status"},[_vm._v(" "+_vm._s(_vm.activityTime(item))+" ")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"es-table__linked",attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" Открыть PDF ")])]}}],null,false,2483293705)}):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"table-vertical-scrollbar"}},[_c('div',{attrs:{"id":"table-scroll"}})])}]

export { render, staticRenderFns }