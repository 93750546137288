import {ADD_CLIENT, SET_CLIENT, SET_CLIENT_CUSTOM_FIELDS, SET_CLIENTS} from "../../mt";

export default {
    [SET_CLIENTS](state, payload) {
        state.clients = payload;
    },
    [SET_CLIENT_CUSTOM_FIELDS](state, payload) {
        state.clientCustomFields = payload;
    },
    ['ADD_CUSTOM_FIELD'](state, payload) {
        state.clientCustomFields.push(payload);
    },
    ['SET_CUSTOM_FIELD'](state, payload) {
        for (const item of state.clientCustomFields) {
            if (item.id === payload.id) {
                item.name = payload.name;
                item.type = payload.type;
                item.values = payload.values;
                break;
            }
        }
    },
    ['REMOVE_CUSTOM_FIELD'](state, payload) {
        state.clientCustomFields.splice(payload, 1);
    },
    ['SET_CLIENTS_BY'](state, payload) {
        payload.forEach(i => {
            if (!state.clients.some(e => e.id === i.id)) {
                state.clients.push(i);
            }
        });
    },
    [SET_CLIENT](state, payload) {
        state.client = payload;
    },
    [ADD_CLIENT](state, payload) {
        if (!state.clients.some(e => e.id === payload.id)) {
            state.clients.unshift(payload);
        }
    }
};
