import {AxiosWrapper} from '@/api/AxiosWrapper';
import {
    SET_CHAT,
    SET_CHAT_INFO,
    SET_CHATS,
    SET_CHATS_FORWARD,
    SET_CHATS_UNREAD_COUNT,
    SET_MESSAGES,
    SET_REMINDERS,
    SET_CHAT_SETTINGS, SET_UPDATE_CLIENT,
} from "../../mt";
import {hasRight} from "@/utils/access/hasRight";

export default {
    getChats(context, payload) {
        if (payload.type) {
            return AxiosWrapper.get(`/api/chats`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_CHATS, response.data.response.data);
                return response.data.response.data;
            });
        } else {
            context.commit(SET_CHATS, []);
            return [];
        }
    },
    addChats(context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/chats`, {queryParams: {...payload}}).then((response) => {
            context.commit('ADD_CHATS', response.data.response.data);
            return response.data.response.data;
        });
    },
    getChatsForward(context, payload) {
        if (payload.type) {
            return AxiosWrapper.getWithoutLoading(`/api/chats`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_CHATS_FORWARD, response.data.response.data);
                return response.data.response.data;
            });
        } else {
            context.commit(SET_CHATS_FORWARD, []);
            return [];
        }
    },
    addChatsForward(context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/chats`, {queryParams: {...payload}}).then((response) => {
            context.commit('ADD_CHATS_FORWARD', response.data.response.data);
            return response.data.response.data;
        });
    },
    getUserChat(context, payload) {
        return AxiosWrapper.get(`/api/chats/user/${payload}`).then((response) => {
            context.commit(SET_CHAT, response.data.response);
            return response.data.response;
        });
    },
    getClientChat(context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/chats/client/${payload}`).then((response) => {
            context.commit(SET_CHAT, response.data.response);
            return response.data.response;
        });
    },
    createGroupChat(context, payload) {
        return AxiosWrapper.post(`/api/chats/group/create`, payload);
    },
    getChatInfo(context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/chats/${payload}`).then((response) => {
            context.commit(SET_CHAT_INFO, response.data.response);
            return response;
        });
    },
    sendMessage(context, payload) {
        if (payload.chatId) {
            let body = {message: payload.message};
            if (payload.script_id) {
                body.script_id = payload.script_id;
            }
            return AxiosWrapper.post(`/api/chats/${payload.chatId}/send`, body);
        } else if (payload.get('chatId')) {
            return AxiosWrapper.post(`/api/chats/${payload.get('chatId')}/send`,
                payload
            );
        }
    },
    editMessage(context, payload) {
        if (payload.chatId) {
            let body = {message: payload.message};
            if (payload.script_id) {
                body.script_id = payload.script_id;
            }
            return AxiosWrapper.put(`/api/chats/${payload.chatId}/message/${payload.id}`, body);
        } else if (payload.get('chatId')) {
            payload.set('_method', 'PUT')
            return AxiosWrapper.post(`/api/chats/${payload.get('chatId')}/message/${payload.get('id')}`,
                payload
            );
        }
    },
    forwardMessages(context, payload) {
        return AxiosWrapper.post(`/api/chats/${payload.chat_id}/forward`, {chat_id: payload.forward_chat_id, chat_messages: payload.chat_messages}).then((response) => {
            context.commit('ADD_FORWARDED_MESSAGES', {messages: response.data.response, chat_id: payload.forward_chat_id});
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Сообщения были пересланы`,
                    type: 'success',
                    timeout: 1000,
                },
                { root: true }
            );
            return response.data;
        });
    },
    deleteMessage(context, payload) {
        return AxiosWrapper.delete(`/api/chats/${payload.chatId}/message/${payload.id}`, {queryParams: {...payload.data}}).then((response) => {
            return response.data.response.data;
        });
    },
    getMessages(context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/chats/${payload.chatId}/messages`, {queryParams: {...payload.data}}).then((response) => {
            context.commit(SET_MESSAGES, response.data.response.data);
            return response.data.response.data;
        });
    },
    addMessages(context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/chats/${payload.chatId}/messages`, {queryParams: {...payload.data}}).then((response) => {
            context.commit('ADD_MESSAGES', response.data.response.data);
            return response.data.response.data;
        });
    },
    setLocalChats(context, payload) {
        context.commit('SET_CHATS_LOCAL', payload);
    },
    removeLocalChat(context, payload) {
        context.commit('REMOVE_CHAT_LOCAL', payload);
    },
    pinChat(context, payload) {
        context.state.chatInfo.is_pinned = true;
        if (payload)
            return AxiosWrapper.post(`/api/chats/${payload}/pin`);
    },
    unpinChat(context, payload) {
        context.state.chatInfo.is_pinned = false;
        if (payload)
            return AxiosWrapper.post(`/api/chats/${payload}/unpin`);
    },
    changeChatUser(context, payload) {
        return AxiosWrapper.post(`/api/chats/${payload.chat_id}/change-user`, {user_id: payload.user_id});
    },
    setChatType (context, payload) {
        context.commit('SET_LOCAL_CHAT_TYPE', payload);
    },
    addUserToGroupChat(context, payload) {
        return AxiosWrapper.post(`/api/chats/group/${payload.chat_id}/add-user`, {user_id: payload.user_id});
    },
    removeUserFromGroupChat(context, payload) {
        return AxiosWrapper.post(`/api/chats/group/${payload.chat_id}/remove-user`, {user_id: payload.user_id});
    },
    getChatsUnreadCount(context) {
        return AxiosWrapper.getWithoutLoading(`/api/chats/unread-chats-count`).then((response) => {
            context.commit(SET_CHATS_UNREAD_COUNT, response.data.response.count);
            return response.data.response;
        });
    },
    readChatMessages(context) {
        if (context.state.chatInfo.id) {
            return AxiosWrapper.post(`/api/chats/${context.state.chatInfo.id}/mark-as-read`).then((response) => {
                if (response) {
                    context.commit('REMOVE_CHAT_UNREAD_MESSAGE');
                    context.commit('REMOVE_CHATS_UNREAD_COUNT');
                }
            });
        }
    },
    removeChatInfo (context) {
        context.commit(SET_CHAT_INFO, {});
    },
    removeMessage (context, payload) {
        context.commit('REMOVE_MESSAGE', payload);
    },
    updateMessage (context, payload) {
        context.commit('UPDATE_MESSAGE', payload);
    },
    getChatAttachment (context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/chats/${payload.chat_id}/attachments/${payload.attachment_id}/file`).then((response) => {
            return response.data;
        });
    },
    updateChatInfoFields (context, payload) {
        context.commit('UPDATE_CHAT_INFO_FIELDS', payload);
    },
    getReminders (context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/reminders`, {queryParams: payload}).then((response) => {
            context.commit(SET_REMINDERS, response.data.response);
            return response.data;
        });
    },
    createReminders (context, payload) {
        return AxiosWrapper.post(`/api/reminders`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Напоминание было создано`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data;
        });
    },
    addReminder (context, payload) {
        context.commit('ADD_REMINDER', payload);
    },
    removeReminders (context, payload) {
        return AxiosWrapper.delete(`/api/reminders/${payload.id}`).then((response) => {
            context.commit('REMOVE_REMINDER', payload);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Напоминание было удалено`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data;
        });
    },
    setClientUpdate (context, payload) {
        context.commit(SET_UPDATE_CLIENT, payload);
    },
    getChatSettings (context) {
        if (hasRight('chat.settings')) {
            return AxiosWrapper.getWithoutLoading(`/api/settings/chats`).then((response) => {
                context.commit(SET_CHAT_SETTINGS, response.data.response);
                return response.data.response;
            });
        }
    },
    setChatSettings (context, payload) {
        return AxiosWrapper.post(`/api/settings/chats`, payload).then((response) => {
            context.commit(SET_CHAT_SETTINGS, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data.response;
        });
    },
};
