var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"modal-create settings"},[_c('v-form',{ref:"form"},[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"text-h5 text-center mb-11"},[_vm._v("Общие настройки почты")])]),_c('v-card-text',{staticClass:"modal-create__form"},[_c('div',{staticClass:"close-dialog",on:{"click":_vm.closeDialog}},[_c('img',{attrs:{"src":require('@/assets/img/common/close-dialog.svg')}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"evi-radio-button-group",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Автоматически создавать клиентов "),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"#289E80"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},[_vm._v(" Настройка отвечает за создание клиентов "),_c('br'),_vm._v(" на основе писем. Если вам пишут впервые, то будет создан клиент, "),_c('br'),_vm._v(" а его почтовый адрес и первое сообщение сохранятся в карточке клиента. ")])],1)]},proxy:true}]),model:{value:(_vm.mailCreateClientsBasedOnEmail),callback:function ($$v) {_vm.mailCreateClientsBasedOnEmail=$$v},expression:"mailCreateClientsBasedOnEmail"}},[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.radios),function(item){return _c('v-radio',{key:item.value,staticClass:"mb-0 mr-6 evi-radio",attrs:{"label":item.name,"value":item.value,"color":"#45BF6A"}})}),1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"item-page__text-field"},[_c('small',[_vm._v(" Email-адреса, которым не создавать клиентов "),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"#289E80"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},[_vm._v(" Заданным через точку с запятой адресам система "),_c('br'),_vm._v(" не будет создавать карточки клиентов. ")])],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-textarea',{ref:"text-message",staticClass:"evi-textarea",attrs:{"no-resize":"","color":"#44D370","hide-details":"","placeholder":'example@domain.ru;\nsupport@domain.ru;\nno-reply@domain.ru;',"height":"200"},model:{value:(_vm.mailCreateClientsIgnoreEmails),callback:function ($$v) {_vm.mailCreateClientsIgnoreEmails=$$v},expression:"mailCreateClientsIgnoreEmails"}})],1)])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"evi-radio-button-group",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Автообновление "),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"#289E80"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},[_vm._v(" Настройка отвечает за автообновление списка писем "),_c('br'),_vm._v(" в почте каждые 30 секунд. ")])],1)]},proxy:true}]),model:{value:(_vm.autoUpdate),callback:function ($$v) {_vm.autoUpdate=$$v},expression:"autoUpdate"}},[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.radios),function(item){return _c('v-radio',{key:item.value,staticClass:"mb-0 mr-6 evi-radio",attrs:{"label":item.name,"value":item.value,"color":"#45BF6A"}})}),1)])],1)],1),_c('v-card-actions',{staticClass:"modal-create__actions"},[_c('v-btn',{staticClass:"evi-button-green evi-button-green--fill",on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }