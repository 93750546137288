<template>
  <v-card class="modal-create settings">
    <v-form ref="form">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Общие настройки почты</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-col
            cols="12"
        >
          <v-radio-group
              v-model="mailCreateClientsBasedOnEmail"
              class="evi-radio-button-group"
          >
            <template v-slot:label>
              Автоматически создавать клиентов
              <v-menu
                  top
                  offset-y
                  open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on">
                    <v-icon size="18" color="#289E80">mdi-information-outline</v-icon>
                  </strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  Настройка отвечает за создание клиентов
                  <br>
                  на основе писем. Если вам пишут впервые, то будет создан клиент,
                  <br>
                  а его почтовый адрес и первое сообщение сохранятся в карточке клиента.
                </v-list-item>
              </v-menu>
            </template>
            <div class="d-flex align-center">
              <v-radio
                  v-for="item in radios"
                  :label="item.name"
                  :value="item.value"
                  :key="item.value"
                  class="mb-0 mr-6 evi-radio"
                  color="#45BF6A"
              />
            </div>
          </v-radio-group>
        </v-col>
        <v-col
            cols="12"
        >
          <div class="item-page__text-field">
            <small>
              Email-адреса, которым не создавать клиентов
              <v-menu
                  top
                  offset-y
                  open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on">
                    <v-icon size="18" color="#289E80">mdi-information-outline</v-icon>
                  </strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  Заданным через точку с запятой адресам система
                  <br>
                  не будет создавать карточки клиентов.
                </v-list-item>
              </v-menu>
            </small>
            <div class="d-flex align-center">
              <v-textarea
                  no-resize
                  class="evi-textarea"
                  color="#44D370"
                  v-model="mailCreateClientsIgnoreEmails"
                  ref="text-message"
                  hide-details
                  :placeholder="'example@domain.ru;\nsupport@domain.ru;\nno-reply@domain.ru;'"
                  height="200"
              >
              </v-textarea>
            </div>
          </div>
        </v-col>
        <v-col
            cols="12"
        >
          <v-radio-group
              v-model="autoUpdate"
              class="evi-radio-button-group"
          >
            <template v-slot:label>
              Автообновление
              <v-menu
                  top
                  offset-y
                  open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on">
                    <v-icon size="18" color="#289E80">mdi-information-outline</v-icon>
                  </strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  Настройка отвечает за автообновление списка писем
                  <br>
                  в почте каждые 30 секунд.
                </v-list-item>
              </v-menu>
            </template>
            <div class="d-flex align-center">
              <v-radio
                  v-for="item in radios"
                  :label="item.name"
                  :value="item.value"
                  :key="item.value"
                  class="mb-0 mr-6 evi-radio"
                  color="#45BF6A"
              />
            </div>
          </v-radio-group>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {eventBus} from "../../main";

import {mapActions, mapState} from "vuex";

export default {
  name: "MailSettingsModal",
  data: () => ({
    autoUpdate: true,
    radios: [
      {
        name: 'Включено',
        value: true,
      },
      {
        name: 'Выключено',
        value: false,
      },
    ],
    mailCreateClientsIgnoreEmails: '',
    mailCreateClientsBasedOnEmail: true,
  }),
  computed: {
    ...mapState('mail', ['settings']),
  },
  methods: {
    ...mapActions({
      getSettings: 'mail/getSettings',
      setSettings: 'mail/setSettings',
    }),
    closeDialog() {
      this.$emit('close');
    },
    save() {
      this.setSettings({
        mail_create_clients_based_on_email: this.mailCreateClientsBasedOnEmail,
        mail_create_clients_ignore_emails: this.mailCreateClientsIgnoreEmails
            .replaceAll(',', ';')
            .replaceAll(' ', '')
            .replaceAll('\n', ''),
      });
      //автобновление
      eventBus.$emit('autoUpdateMailMessagesChanged', this.autoUpdate);
    }
  },
  mounted() {
    this.autoUpdate = localStorage.getItem("auto_mail_messages_update") === "true";
    this.getSettings();
  },
  watch: {
    settings(val) {
      if (val) {
        this.mailCreateClientsBasedOnEmail = val.mail_create_clients_based_on_email;
        if (val.mail_create_clients_ignore_emails) {
          this.mailCreateClientsIgnoreEmails = val.mail_create_clients_ignore_emails.replaceAll(';', ';\n');
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
