import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_PRODUCT, SET_PRODUCTS} from "../../mt";
import store  from '@/store';
import {hasRight} from '@/utils/access/hasRight';

export default {
    getProducts (context) {
        if (hasRight('products.view'))
            return AxiosWrapper.get(`/api/products`).then((response) => {
                context.commit(SET_PRODUCTS, response.data.response);
                return response;
            });
    },
    getProductsBy (context, payload) {
        if (hasRight('products.view'))
            return AxiosWrapper.getWithoutLoading(`/api/products`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_PRODUCTS, response.data.response.data);
                return response.data.response;
            });
    },
    addProductsBy (context, payload) {
        if (hasRight('products.view'))
            return AxiosWrapper.getWithoutLoading(`/api/products`, {queryParams: {...payload}}).then((response) => {
                context.commit('ADD_PRODUCTS', response.data.response.data);
                return response.data.response;
            });
    },
    getProductsPages (context, payload) {
        if (hasRight('products.view'))
            return AxiosWrapper.get(`/api/products`, {queryParams: {...payload}}).then((response) => {
                context.commit(SET_PRODUCTS, response.data.response.data);
                store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
                return response;
            });
    },
    getProduct (context, payload) {
        return AxiosWrapper.get(`/api/products/${payload}`).then((response) => {
            context.commit(SET_PRODUCT, response.data.response);
            return response;
        });
    },
    editProduct (context, payload) {
        return AxiosWrapper.put(`/api/products/${payload.id}`, payload).then((response) => {
            context.commit(SET_PRODUCT, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    createProduct (context, payload) {
        return AxiosWrapper.post(`/api/products`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Продукт "${payload.name}" успешно создан!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteProduct (context, payload) {
        return AxiosWrapper.delete(`/api/products/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Продукт ${payload.name} был удален`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    createSize (context, payload) {
        return AxiosWrapper.post(`/api/products/${payload.productId}/sizes`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Размер "${payload.value}" добавлен!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            context.commit(SET_PRODUCT, response.data.response);
            return response;
        });
    },
    editSize (context, payload) {
        return AxiosWrapper.put(`/api/products/${payload.productId}/sizes/${payload.productSize}`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Размер "${payload.value}" изменен!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            context.commit(SET_PRODUCT, response.data.response);
            return response;
        });
    },
    removeSize (context, payload) {
        return AxiosWrapper.delete(`/api/products/${payload.productId}/sizes/${payload.productSize}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Размер "${payload.value}" удален!`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            context.commit(SET_PRODUCT, response.data.response);
            return response;
        });
    },
};
