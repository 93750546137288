import { AxiosWrapper } from '@/api/AxiosWrapper';
import {ADD_ALERT, REMOVE_ALERT, SET_MENU} from "../../mt";
import {hasRightInclude} from '@/utils/access/hasRight';

export default {
    setMenu (context) {

        function hasPermissions (array, name) {
            array.forEach(c => {
                if (c.permission === "any" || hasRightInclude(`${c.permission}.`)) {
                    menu[name].push(c);
                }
            });
        }

        //проверка прав на отображение пунктов меню
        let menu = {
            common: [],
            company: [],
            settings: [],
            contact: [],
        };

        let common = [
            {
                title: 'Задачи',
                id: 11,
                route: 'tasks',
                permission: 'tasks',
            },
            {
                title: 'Аналитика',
                id: 12,
                route: 'analytics',
                permission: 'analytics',
            },
            {
                title: 'Клиенты',
                id: 15,
                route: 'clients',
                permission: 'clients',
            },
            {
                title: 'Продукты',
                id: 16,
                route: 'products',
                permission: 'products',
            },
            {
                title: 'Сделки',
                id: 14,
                route: 'deals',
                permission: 'deals',
            },
            {
                title: 'Счета',
                id: 18,
                route: 'bills',
                permission: 'bills',
            },
            {
                title: 'Чаты',
                id: 17,
                route: 'chat',
                permission: 'any',
            },
            {
                title: 'Почта',
                id: 13,
                route: 'mail',
                permission: 'mail',
                isBeta: true,
            },
            {
                title: 'Рассылки',
                id: 19,
                route: 'mailers',
                permission: 'mailers',
            },
        ];

        let company = [
            {
                title: 'Сотрудники',
                id: 41,
                route: 'users',
                permission: 'users',
            },
            {
                title: 'Подразделения',
                id: 42,
                route: 'subdivisions',
                permission: 'subdivisions',
            },
            {
                title: 'Журнал событий',
                id: 43,
                route: 'event-log',
                permission: 'activitylog',
            },
        ];

        let settings = [
            {
                title: 'Интеграции',
                id: 21,
                route: 'integrations',
                permission: 'integrations',
            },
            {
                title: 'Быстрые фразы',
                id: 22,
                route: 'scripts',
                permission: 'scripts',
            },
            {
                title: 'Автоматизация',
                id: 26,
                route: 'automations',
                permission: 'automations',
            },
            {
                title: 'Роли и права',
                id: 23,
                route: 'permissions',
                permission: 'permissions',
            },
            {
                title: 'Cтатусы сделок',
                id: 24,
                route: 'deal-statuses',
                permission: 'dealStatuses',
            },
            {
                title: 'Cтатусы клиентов',
                id: 241,
                route: 'client-statuses',
                permission: 'clientStatuses',
            },
            {
                title: 'Теги',
                id: 25,
                route: 'tags',
                permission: 'tags',
            },
        ];

        let contact = [
            {
                title: 'Тарифы и оплата',
                id: 31,
                route: 'payments',
                permission: 'payment',
            },
            {
                title: 'Обратная связь',
                id: 32,
                route: 'feedback',
                permission: 'any',
            }
        ];

        hasPermissions(common, 'common');
        hasPermissions(company, 'company');
        hasPermissions(settings, 'settings');
        hasPermissions(contact, 'contact');

        context.commit(SET_MENU, menu);
    },
    setAlert(context, payload) {
        context.commit(ADD_ALERT, payload);
    },
    removeAlertCounter({ commit }, { id, timeout }) {
        setTimeout(function () {
            commit(REMOVE_ALERT, id);
        }, timeout);
    },
    sendFeedback (context, payload) {
        return AxiosWrapper.commonPost(`/api/feedback`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Ваше обращение отправлено!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    }
};
