export default {
    scriptGroups: [],
    scripts: [],
    groupScripts: [],
    keywords: [
        {
            name: 'Клиент',
            values: [
                {
                    text: 'Имя',
                    value: '[Клиент.Имя]',
                },
                {
                    text: 'Телефон',
                    value: '[Клиент.Телефон]',
                },
                {
                    text: 'Дата рождения',
                    value: '[Клиент.Дата рождения]',
                },
                {
                    text: 'Email',
                    value: '[Клиент.Email]',
                },
                {
                    text: 'Город',
                    value: '[Клиент.Город]',
                },
                {
                    text: 'Адрес',
                    value: '[Клиент.Адрес]',
                },
            ]
        },
        {
            name: 'Сделка',
            comment: 'Учитывается выбранная сделка у клиента',
            values: [
                {
                    text: 'Номер',
                    value: '[Сделка.Номер]',
                },
                {
                    text: 'Скидка',
                    value: '[Сделка.Скидка]',
                },
                {
                    text: 'Исполнитель',
                    value: '[Сделка.Исполнитель]',
                },
                {
                    text: 'Корзина',
                    value: '[Сделка.Корзина]',
                },
                {
                    text: 'Сумма корзины',
                    value: '[Сделка.Сумма корзины]',
                },
                {
                    text: 'Стоимость доставки',
                    value: '[Сделка.Стоимость доставки]',
                },
                {
                    text: 'Итоговая сумма',
                    value: '[Сделка.Итоговая сумма]',
                },
                {
                    text: 'Предоплата',
                    value: '[Сделка.Предоплата]',
                },
                {
                    text: 'При получении',
                    value: '[Сделка.При получении]',
                },
            ]
        },
        {
            name: 'Доставка',
            comment: 'Учитывается доставка у выбранной сделки клиента',
            values: [
                {
                    text: 'Служба доставки',
                    value: '[Доставка.Служба доставки]',
                },
                {
                    text: 'Способ доставки',
                    value: '[Доставка.Способ доставки]',
                },
                {
                    text: 'ФИО',
                    value: '[Доставка.ФИО]',
                },
                {
                    text: 'Номер телефона',
                    value: '[Доставка.Номер телефона]',
                },
                {
                    text: 'Страна',
                    value: '[Доставка.Страна]',
                },
                {
                    text: 'Город',
                    value: '[Доставка.Город]',
                },
                {
                    text: 'Адрес',
                    value: '[Доставка.Адрес]',
                },
                {
                    text: 'Индекс',
                    value: '[Доставка.Индекс]',
                },
                {
                    text: 'Дата отправки',
                    value: '[Доставка.Дата отправки]',
                },
                {
                    text: 'Дата доставки',
                    value: '[Доставка.Дата доставки]',
                },
                {
                    text: 'Время доставки с',
                    value: '[Доставка.Время доставки с]',
                },
                {
                    text: 'Время доставки по',
                    value: '[Доставка.Время доставки по]',
                },
                {
                    text: 'Статус доставки',
                    value: '[Доставка.Статус доставки]',
                },
                {
                    text: 'Примечание к доставке',
                    value: '[Доставка.Примечание к доставке]',
                },
                {
                    text: 'Примечание от клиента',
                    value: '[Доставка.Примечание от клиента]',
                },
                {
                    text: 'Трек номер',
                    value: '[Доставка.Трек номер]',
                },
            ]
        },
        {
            name: 'СДЭК',
            comment: 'Если выбрана служба доставки "СДЭК"',
            values: [
                {
                    text: 'Тариф',
                    value: '[СДЭК.Тариф]',
                },
                {
                    text: 'ФИО получателя',
                    value: '[СДЭК.ФИО получателя]',
                },
                {
                    text: 'Номер телефона получателя',
                    value: '[СДЭК.Номер телефона получателя]',
                },
                {
                    text: 'Название компании',
                    value: '[СДЭК.Название компании]',
                },
                {
                    text: 'Вес посылки',
                    value: '[СДЭК.Вес посылки]',
                },
                {
                    text: 'Длина посылки',
                    value: '[СДЭК.Длина посылки]',
                },
                {
                    text: 'Ширина посылки',
                    value: '[СДЭК.Ширина посылки]',
                },
                {
                    text: 'Высота посылки',
                    value: '[СДЭК.Высота посылки]',
                },
                {
                    text: 'Откуда (нп)',
                    value: '[СДЭК.Откуда]',
                },
                {
                    text: 'Куда (нп)',
                    value: '[СДЭК.Куда]',
                },
                {
                    text: 'Из ПВЗ',
                    value: '[СДЭК.Из ПВЗ]',
                },
                {
                    text: 'В ПВЗ',
                    value: '[СДЭК.В ПВЗ]',
                },
                {
                    text: 'С адреса',
                    value: '[СДЭК.С адреса]',
                },
                {
                    text: 'На адрес',
                    value: '[СДЭК.На адрес]',
                },
                {
                    text: 'Трек номер',
                    value: '[СДЭК.Трек номер]',
                },
            ]
        },
    ],
};
