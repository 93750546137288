import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_DEAL_STATUSES} from "../../mt";
import {hasRight} from '@/utils/access/hasRight';

export default {
    getStatuses (context) {
        if (hasRight('dealStatuses.view'))
            return AxiosWrapper.get(`/api/deal-statuses`).then((response) => {
                response.data.response.sort(function (a, b) {
                    return b.id - a.id;
                });
                context.commit(SET_DEAL_STATUSES, response.data.response);
                return response;
            });
    },
    getStatusesBy (context) {
        if (hasRight('dealStatuses.view'))
            return AxiosWrapper.getWithoutLoading(`/api/deal-statuses`).then((response) => {
                response.data.response.sort(function (a, b) {
                    return b.id - a.id;
                });
                context.commit(SET_DEAL_STATUSES, response.data.response);
                return response;
            });
    },
    createStatus (context) {
        let payload = {name: `Статус ${context.state.statuses.length + 1}`, color: '#99AAB5', stage: 'active'};
        return AxiosWrapper.post(`/api/deal-statuses`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Статус "${payload.name}" сохранен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteStatus (context, payload) {
        return AxiosWrapper.delete(`/api/deal-statuses/${payload.id}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Статус "${payload.name}" удален`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    getSelectedStatus (context, payload) {
        return AxiosWrapper.get(`/api/deal-statuses/${payload}`).then((response) => {
            return response.data.response;
        });
    },
    saveSelectedStatus (context, payload) {
        return AxiosWrapper.put(`/api/deal-statuses/${payload.id}`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Статус "${payload.name}" сохранен`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            context.commit('SET_DEAL_STATUSES_CHANGES', response.data.response);
            return response.data.response;
        });
    },
};
