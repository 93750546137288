import {SET_PAYMENT_INFO} from "../../mt";

export default {
    [SET_PAYMENT_INFO](state, payload) {
        state.paymentInfo = payload;
    },
    ['REMOVE_ACTIVE_USER'](state) {
        state.paymentInfo.active_users_count -= 1;
    },
    ['RESTORE_ACTIVE_USER'](state) {
        state.paymentInfo.active_users_count++;
    },
};
