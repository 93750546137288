import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_INTEGRATION, SET_INTEGRATIONS, SET_INTEGRATIONS_SETTINGS} from "../../mt";
import store  from '@/store';
import {hasRight} from "@/utils/access/hasRight";

export default {
    getIntegrations (context) {
        return AxiosWrapper.get(`/api/integrations`).then((response) => {
            context.commit(SET_INTEGRATIONS, response.data.response);
            return response;
        });
    },
    getIntegrationsBy (context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/integrations`, {queryParams: {...payload}}).then((response) => {
            context.commit(SET_INTEGRATIONS, response.data.response.data);
            return response.data.response;
        });
    },
    addIntegrationsBy (context, payload) {
        return AxiosWrapper.getWithoutLoading(`/api/integrations`, {queryParams: {...payload}}).then((response) => {
            context.commit('ADD_INTEGRATIONS', response.data.response.data);
            return response.data.response;
        });
    },
    getIntegrationsPages (context, payload) {
        return AxiosWrapper.get(`/api/integrations`, {queryParams: {...payload}}).then((response) => {
            context.commit(SET_INTEGRATIONS, response.data.response.data);
            store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
            return response;
        });
    },
    getFormsPages (context, payload) {
        return AxiosWrapper.get(`/api/integrations/form`, {queryParams: {...payload}}).then((response) => {
            context.commit(SET_INTEGRATIONS, response.data.response.data);
            store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
            return response;
        });
    },
    getIntegration (context, payload) {
        return AxiosWrapper.get(`/api/integrations/${payload}`).then((response) => {
            context.commit(SET_INTEGRATION, response.data.response);
            return response.data.response;
        });
    },
    sessionIntegrationVK () {
        return AxiosWrapper.post(`/api/integrations/vk/session`).then((response) => {
            return response.data.response.url;
        });
    },
    createIntegrationVK (context, payload) {
        return AxiosWrapper.post(`/api/integrations/vk/create`, payload).then((response) => {
            return response.data.response.url;
        });
    },
    sessionIntegrationInst () {
        return AxiosWrapper.post(`/api/integrations/instagram/session`).then((response) => {
            return response.data.response.url;
        });
    },
    createIntegrationInst (context, payload) {
        return AxiosWrapper.post(`/api/integrations/instagram/create`, payload).then((response) => {
            return response.data.response.url;
        });
    },
    createIntegrationTg (context, payload) {
        return AxiosWrapper.post(`/api/integrations/telegram/create`, payload).then((response) => {
            return response.data.response;
        });
    },
    createIntegrationSdek (context, payload) {
        return AxiosWrapper.post(`/api/integrations/cdek`, payload).then((response) => {
            return response.data.response;
        });
    },
    createIntegrationForms (context, payload) {
        return AxiosWrapper.post(`/api/integrations/form/`, payload).then((response) => {
            return response.data.response;
        });
    },
    deleteIntegration (context, payload) {
        return AxiosWrapper.delete(`/api/integrations/${payload.id}`).then((response) => {
            if (response) {
                context.commit(
                    'common/ADD_ALERT',
                    {
                        text: `Интеграция "${payload.name}" была удалена`,
                        type: 'warning',
                        timeout: 2000,
                    },
                    { root: true }
                );
            }
        });
    },
    deleteIntegrationForm (context, payload) {
        return AxiosWrapper.delete(`/api/integrations/form/${payload.id}`).then((response) => {
            if (response) {
                context.commit(
                    'common/ADD_ALERT',
                    {
                        text: `Интеграция "${payload.name}" была удалена`,
                        type: 'warning',
                        timeout: 2000,
                    },
                    { root: true }
                );
            }
        });
    },
    editIntegrationResponsibleUsers (context, payload) {
        return AxiosWrapper.post(`/api/integrations/${payload.id}/users`, {users: payload.selectedUsers}).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editIntegrationTags (context, payload) {
        return AxiosWrapper.post(`/api/integrations/${payload.id}/tags`, {tags: payload.selectedTags}).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editIntegrationForms (context, payload) {
        return AxiosWrapper.put(`/api/integrations/form/${payload.id}`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editIntegrationSdek (context, payload) {
        return AxiosWrapper.put(`/api/integrations/cdek/${payload.id}`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editIntegrationFormsNoAlert (context, payload) {
        return AxiosWrapper.put(`/api/integrations/form/${payload.id}`, payload).then((response) => {
            return response;
        });
    },
    getIntegrationsSettings (context) {
        if (hasRight('integrations.settings')) {
            return AxiosWrapper.getWithoutLoading(`/api/settings/integrations`).then((response) => {
                context.commit(SET_INTEGRATIONS_SETTINGS, response.data.response);
                return response.data.response;
            });
        }
    },
    setIntegrationsSettings (context, payload) {
        return AxiosWrapper.post(`/api/settings/integrations`, payload).then((response) => {
            context.commit(SET_INTEGRATIONS_SETTINGS, response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data.response;
        });
    },
    editIntegrationDistributionTags (context, payload) {
        return AxiosWrapper.post(`/api/integrations/${payload.id}/distribution-tags`, {distribution_tags: payload.selectedDistributionTags}).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    createMailIntegration (context, payload) {
        return AxiosWrapper.post(`/api/integrations/mail/`, payload).then((response) => {
            return response.data.response;
        });
    },
};
