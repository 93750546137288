<template>
  <v-app>
    <!-- content -->
    <component :is="layout" @getNotificationsData="getNotificationsData" @darkScroll="darkScroll"
               @lightScroll="lightScroll">
      <Transition name="fade" mode="in-out">
        <router-view/>
      </Transition>
    </component>
    <list-alerts/>
  </v-app>
</template>

<script>
import ListAlerts from "./components/сommon/ListAlerts";
import axios from 'axios';
import store from '@/store';
import {mapActions, mapState} from "vuex";
import {wsConnect} from "@/websocket/ws"

export default {
  name: 'App',
  components: {ListAlerts},
  data() {
    return {
      newMessage: null,
    }
  },
  computed: {
    layout() {
      if (this.$route.name) {
        return `${this.$route.meta.layout || 'main'}-layout`;
      } else {
        return 'clean-layout';
      }
    },
    ...mapState("user", ["webSocketData", "profile"]),
    ...mapState("chat", ["chats", "chatType", "chatInfo"]),
    ...mapState("chat", ["chatsUnreadCount"]),
    ...mapState("integrations", ["settings"]),
    darkTheme() {
      return localStorage.getItem("dark_theme") === "true";
    }
  },
  created() {
    const token = store.state.user.accessToken;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    if (this.webSocketData.key) {
      this.wcConnect();
    }

    if (this.darkTheme) {
      this.darkScroll();
    }
  },
  methods: {
    ...mapActions({
      getNotificationsCount: 'notifications/getNotificationsCount',
      getNotifications: 'notifications/getNotifications',
      pushLocalNotificaions: 'notifications/pushLocalNotificaions',
      setLocalChats: 'chat/setLocalChats',
      removeLocalChat: 'chat/removeLocalChat',
      removeMessage: 'chat/removeMessage',
      updateMessage: 'chat/updateMessage',
      getChatInfo: 'chat/getChatInfo',
      setClientUpdate: 'chat/setClientUpdate',
      addReminder: 'chat/addReminder',
    }),
    darkScroll() {
      document.body.style.backgroundColor = "#16151A";
      document.styleSheets[2].addRule("::-webkit-scrollbar-thumb", "background: #36343E !important;");
      document.styleSheets[2].addRule(".evi-sidebar ::-webkit-scrollbar-thumb", "background: #1F1E25 !important;");
      document.styleSheets[2].addRule("::-webkit-scrollbar", "background: transparent !important;");
    },
    lightScroll() {
      document.body.style.backgroundColor = "#ffffff";
      document.styleSheets[2].addRule("::-webkit-scrollbar-thumb", "background: #E5E5E5 !important;");
      document.styleSheets[2].addRule(".evi-sidebar ::-webkit-scrollbar-thumb", "background: #E5E5E5 !important;");
      document.styleSheets[2].addRule("::-webkit-scrollbar", "background: transparent !important;");
    },
    getNotificationsData() {
      this.getNotificationsCount();
      this.getNotifications();
    },
    wcConnect() {
      wsConnect();

      //socket
      this.$nextTick(() => {
        this.$echo.private(`notifications.${this.profile.id}`).notification((payload) => {
          this.pushLocalNotificaions(payload);
          if (localStorage.getItem("are_notifications_muted") !== "true") {
            let audio = new Audio(require('@/assets/sounds/notification.mp3'));
            audio.play();
          }
        });

        this.$echo.private(`chat.${this.profile.id}`)
            .listen('ChatMessage', payload => {
              this.$nextTick(() => {
                this.setLocalChats(payload);
                if (localStorage.getItem("are_chats_muted") !== "true") {
                  let audio = new Audio(require('@/assets/sounds/message.mp3'));
                  audio.play();
                }
              });
            })
            .listen('ChatMessageRemove', payload => {
              this.removeMessage(payload);
            })
            .listen('ChatMessageChanged', payload => {
              this.updateMessage(payload)
            })
            .listen('ChatRemove', payload => {
              //Если в настройках указано, то пользователь видит только свои диалоги
              if (!this.settings.integrations_users_view_all_chats && this.$route.path.includes('chat')) {
                if (this.chatInfo.id === payload.chat_id || this.chatInfo.id === payload.id) {
                  this.$router.push({path: `/chat/`}).catch(() => ({}));
                }
                this.removeLocalChat(payload);
              }
            })
            .listen('ChatAdd', payload => {
              this.setLocalChats(payload);
            });

        //Апдейты
        this.$echo.private(`updates`)
            .listen('EntityUpdated', (payload) => {
              if (payload.name === 'Client') {
                this.setClientUpdate(payload.data);
              }
            });
        //апдейты напоминаний
        this.$echo.private(`updates.${this.profile.id}`)
            .listen('PrivateEntityUpdated', (payload) => {
              if (payload.name === 'Reminder') {
                let today = new Date();
                let tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));

                if (this.$moment(today).format('YYYY-MM-DD') === this.$moment(payload.data.date).format('YYYY-MM-DD')) {
                  this.addReminder({
                    name: 'today',
                    data: payload.data,
                  });
                } else if (this.$moment(tomorrow).format('YYYY-MM-DD') === this.$moment(payload.data.date).format('YYYY-MM-DD')) {
                  this.addReminder({
                    name: 'tomorrow',
                    data: payload.data,
                  });
                } else if (this.$moment(payload.data.date).format('YYYY-MM-DD').valueOf() > this.$moment(today).format('YYYY-MM-DD').valueOf()) {
                  this.addReminder({
                    name: 'future',
                    data: payload.data,
                  });
                } else {
                  this.addReminder({
                    name: 'outdated',
                    data: payload.data,
                  });
                }
              }
            });
      });

    },
    wsDisconnect() {
      this.$echo.leave(`notifications.${this.profile.id}`);
      this.$echo.leave(`chat.${this.profile.id}`);
      this.$echo.leave(`integrations.${this.profile.id}`);
      this.$echo.leave(`updates`);
      this.$echo.leave(`updates.${this.profile.id}`);
    },
  },
  watch: {
    'webSocketData.key'(val) {
      if (val) {
        this.wcConnect();
      } else {
        this.wsDisconnect();
      }
    },
    // chatsUnreadCount(v) {
    //   let messagePluralize = ['новое сообщение', 'новых сообщения', 'новых сообщений'];
    //
    //   document.title = `${this.$route.meta.title} - Evi Sales`;
    //
    //   if (v) {
    //     this.newMessage = setInterval(() => {
    //       document.title = `${pluralize(v, messagePluralize)}`;
    //
    //       setTimeout(() => {
    //         document.title = `${this.$route.meta.title} - Evi Sales`;
    //       }, 1000);
    //     }, 2000);
    //   } else {
    //     clearInterval(this.newMessage);
    //     this.newMessage = null;
    //   }
    // }
  }
};
</script>
