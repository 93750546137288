var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"modal-create settings"},[_c('v-form',{ref:"form"},[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"text-h5 text-center mb-11"},[_vm._v("Общие настройки сделок")])]),_c('v-card-text',{staticClass:"modal-create__form"},[_c('div',{staticClass:"close-dialog",on:{"click":_vm.closeDialog}},[_c('img',{attrs:{"src":require('@/assets/img/common/close-dialog.svg')}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"evi-radio-button-group",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Автоматическое создание сделки "),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"#289E80"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},[_vm._v(" Если клиент пишет впервые или после успешного закрытия последней сделки, "),_c('br'),_vm._v(" при ответе сотрудника система сама создает сделку "),_c('br'),_vm._v(" с заполненными полями по умолчанию. ")])],1)]},proxy:true}]),model:{value:(_vm.autoCreateDeal),callback:function ($$v) {_vm.autoCreateDeal=$$v},expression:"autoCreateDeal"}},[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.radios),function(item){return _c('v-radio',{key:item.value,staticClass:"mb-0 mr-6 evi-radio",attrs:{"label":item.name,"value":item.value,"color":"#45BF6A"}})}),1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('small',{style:({color: '#808386'})},[_vm._v(" Статус сделки по умолчанию "),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"#289E80"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},[_vm._v(" Настройка отвечает за то, какой статус по умолчанию "),_c('br'),_vm._v(" присваивается сделке при автоматическом создании ")])],1)],1),_c('v-autocomplete',{staticClass:"evi-autocomplete mb-2",attrs:{"color":"#44D370","items":_vm.localStatuses,"item-text":"name","item-value":"id","clearable":"","item-color":"green","hide-details":"","placeholder":"Выберите статус"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"evi-autocomplete__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"modal-create__color-select"},[_c('span',{staticClass:"color",style:({backgroundColor: ("" + (item.color))})}),_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"modal-create__color-select"},[_c('span',{staticClass:"color mt-1",style:({backgroundColor: ("" + (item.color))})}),_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.autoStatus),callback:function ($$v) {_vm.autoStatus=$$v},expression:"autoStatus"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"evi-radio-button-group",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Поиск точного совпадения "),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"#289E80"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},[_vm._v(" Если включена эта настройка, то поиск в разделе сделок будет искать одно точное совпадение, "),_c('br'),_vm._v(" а не все результаты, какие включают в себя поисковый запрос. ")])],1)]},proxy:true}]),model:{value:(_vm.dealSearch),callback:function ($$v) {_vm.dealSearch=$$v},expression:"dealSearch"}},[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.radios),function(item){return _c('v-radio',{key:item.value,staticClass:"mb-0 mr-6 evi-radio",attrs:{"label":item.name,"value":item.value,"color":"#45BF6A"}})}),1)])],1)],1),_c('v-card-actions',{staticClass:"modal-create__actions"},[_c('v-btn',{staticClass:"evi-button-green evi-button-green--fill",on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }