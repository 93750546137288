import { AxiosWrapper } from '@/api/AxiosWrapper';
import {SET_NOTIFICATIONS, SET_NOTIFICATIONS_COUNT} from "../../mt";

export default {
    getNotifications (context) {
        return AxiosWrapper.getWithoutLoading(`/api/notifications`).then((response) => {
            context.commit(SET_NOTIFICATIONS, response.data.response);
            return response;
        });
    },
    pushLocalNotificaions (context, payload) {
        if (!context.state.notifications.some(i => i.id === payload.id)) {
            context.commit('SET_NOTIFICATIONS_LOCAL', payload);
            context.commit("SET_NOTIFICATIONS_COUNT_LOCAL");
        }
    },
    getNotificationsCount (context) {
        return AxiosWrapper.getWithoutLoading(`/api/notifications/count`).then((response) => {
            context.commit(SET_NOTIFICATIONS_COUNT, response.data.response.count);
            return response;
        });
    },
    markNotifiIsRead (context, payload) {
        return AxiosWrapper.post(`/api/notifications/markAsRead`, {notifications: payload}).then((response) => {
            return response;
        });
    },
};
