import {SET_MAILER, SET_MAILERS} from "../../mt";

export default {
    [SET_MAILERS](state, payload) {
        state.mailers = payload;
    },
    [SET_MAILER](state, payload) {
        state.mailer = payload;
    },
    ['ADD_MAILERS'](state, payload) {
        payload.forEach(i => {
            if (!state.mailers.some(e => e.id === i.id)) {
                state.mailers.push(i);
            }
        });
    },
};
