import {
    SET_CHAT,
    SET_CHAT_INFO, SET_CHAT_SETTINGS,
    SET_CHATS,
    SET_CHATS_FORWARD,
    SET_CHATS_UNREAD_COUNT,
    SET_MESSAGES,
    SET_REMINDERS, SET_UPDATE_CLIENT
} from "../../mt";

export default {
    [SET_CHATS](state, payload) {
        payload.forEach((i, index) => {
            if (!i.last_message) {
                i.last_message = {
                    created_at: new Date(),
                    message: "Новый чат"
                }
            }

            if (!i.client && i.type === 'outer') {
                payload.splice(index, 1);
            }
        });
        payload.sort(function (a, b) {
            return new Date(b.last_message.created_at) - new Date(a.last_message.created_at);
        });

        if (!payload.some(i => i.type !== state.chatType) || state.chatType === 'pinned' && payload.some(i => i.is_pinned)) {
            state.chats = payload;
        }
    },
    [SET_CHATS_FORWARD](state, payload) {
        payload.forEach((i, index) => {
            if (!i.last_message) {
                i.last_message = {
                    created_at: new Date(),
                    message: "Новый чат"
                }
            }

            if (!i.client && i.type === 'outer') {
                payload.splice(index, 1);
            }
        });
        payload.sort(function (a, b) {
            return new Date(b.last_message.created_at) - new Date(a.last_message.created_at);
        });

        if (!payload.some(i => i.type !== state.chatType) || state.chatType === 'pinned' && payload.some(i => i.is_pinned)) {
            state.chatsForward = payload;
        }
    },
    ['ADD_CHATS'](state, payload) {
        for (let item of payload) {
            if (!state.chats.some(c => c.id === item.id) && state.chatType === item.type) {
                state.chats.push(item)
            }
        }
        // state.chats = [...state.chats, ...payload];
    },
    ['ADD_CHATS_FORWARD'](state, payload) {
        for (let item of payload) {
            if (!state.chatsForward.some(c => c.id === item.id) && state.chatType === item.type) {
                state.chatsForward.push(item)
            }
        }
        // state.chatsForward = [...state.chatsForward, ...payload];
    },
    ['SET_CHATS_LOCAL'](state, payload) {
        //прибавляю счетчик непрочитанных диалогов в чате
        if (payload.unread_chats_count) state.chatsUnreadCount = payload.unread_chats_count;


        //создаем последнее сообщение в диалоге
        // console.warn(payload)
        payload.last_message = {
            created_at: payload.created_at || payload.chat.last_message.created_at,
            id: payload.id || payload.chat_message_id,
        };
        if (payload.message) {
            payload.last_message.message = payload.message;
        }

        if (payload.chat && payload.chat.last_message.message) {
            payload.last_message.message = payload.chat.last_message.message;
        }

        if (payload.user) {
            payload.last_message.user = payload.user;
        }

        if (payload.attachments) {
            payload.last_message.attachments = payload.attachments;
        }

        if (payload.chat) {
            payload.chat_id = payload.chat.id;
        }

        state.chats.forEach(i => {
            if (i.id === payload.chat_id || i.chat_id === payload.chat_id) {
                i.last_message = payload.last_message;
            }

            if (i.id === payload.chat_id &&
                (!state.chatInfo || state.chatInfo.id !== i.id)) {
                //прибавляю счетчик сообщение в диалоге
                i.unread_messages_count = payload.unread_messages_count;
            }
        });

        if (
            !state.chats.some(i => i.id === payload.chat_id || i.chat_id === payload.chat_id) &&
            (state.chatType === "pinned" || payload.type === state.chatType || (payload.chat && payload.chat.type === state.chatType))
        ) {

            state.chats.unshift(payload);

            state.chats.forEach(i => {
                if (i.id === payload.chat_id &&
                    (!state.chatInfo || state.chatInfo.id !== i.id)) {
                    //прибавляю счетчик сообщение в диалоге
                    i.unread_messages_count = payload.unread_messages_count;
                }
            });
        }
    },
    ['REMOVE_CHAT_LOCAL'](state, payload) {
        state.chats.forEach((el, i) => {
            if (el.id === payload.chat_id || el.id === payload.id || el.chat_id === payload.chat_id) {
                state.chats.splice(i, 1);
                if (el.unread_messages_count) {
                    state.chatsUnreadCount -= 1;
                }
            }
        });
    },
    ['SET_LOCAL_CHAT_TYPE'](state, payload) {
        state.chatType = payload;
    },
    [SET_CHAT](state, payload) {
        state.chat = payload;
    },
    [SET_CHAT_INFO](state, payload) {
        state.chatInfo = payload;
    },
    [SET_MESSAGES](state, payload) {
        payload.sort(function (a, b) {
            return new Date(a.id) - new Date(b.id);
        });
        state.messages = payload;
    },
    ['ADD_MESSAGES'](state, payload) {
        payload.forEach(i => {
            i.is_old = true;
            state.messages.sort(function (a, b) {
                return new Date(a.id) - new Date(b.id);
            });
            if (!state.messages.some(m => m.id === i.id))
                state.messages.unshift(i);
        });
    },
    ['ADD_FORWARDED_MESSAGES'](state, payload) {
        if (payload.chat_id === state.chatInfo.id) {
            payload.messages.forEach(i => {
                i.is_old = true;
                state.messages.sort(function (a, b) {
                    return new Date(a.id) - new Date(b.id);
                });
                if (!state.messages.some(m => m.id === i.id)) {
                    state.messages.push(i);
                }
            });
        }
        let forwardChat = state.chats.find(i => i.id === payload.chat_id);
        if (forwardChat) {
            forwardChat.last_message = payload.messages[payload.messages.length - 1];
        }
    },
    [SET_CHATS_UNREAD_COUNT](state, payload) {
        state.chatsUnreadCount = payload;
    },
    ['REMOVE_CHAT_UNREAD_MESSAGE'](state) {
        state.chatInfo.unread_messages_count = 0;

        state.chats.forEach(i => {
            if (i.id === state.chatInfo.id) {
                i.unread_messages_count = 0;
            }
        });

    },
    ['REMOVE_CHATS_UNREAD_COUNT'](state) {
        if (state.chatsUnreadCount > 0)
            state.chatsUnreadCount -= 1;
    },
    ['REMOVE_MESSAGE'](state, payload) {
        state.messages.forEach((m, i) => {
            if (m.id === payload.chat_message_id || m.chat_message_id === payload.chat_message_id) {
                state.messages.splice(i, 1);
            }
        });
        state.chats.forEach(c => {
            if (c.id === payload.chat_id) {
                c.last_message = payload.last_message;
                c.unread_messages_count = payload.unread_messages_count;
            }
        });
        state.chatsUnreadCount = payload.unread_chats_count;
    },
    ['UPDATE_MESSAGE'](state, payload) {
        state.messages.forEach((m, i) => {
            if (m.id === payload.chat_message_id || m.chat_message_id === payload.chat_message_id) {
                state.messages.splice(i, 1, payload);
            }
        });
        state.chats.forEach(c => {
            if (c.id === payload.chat_id && c.last_message.id === payload.chat_message_id) {
                c.last_message = payload;
                c.unread_messages_count = payload.unread_messages_count;
            }
        });
        state.chatsUnreadCount = payload.unread_chats_count;
    },
    ['UPDATE_CHAT_INFO_FIELDS'](state, payload) {
        state.chats.forEach(i => {
            if (i.client.id === payload.id) {
                i.client.tags = payload.tags;
                i.client.client_status = payload.client_status;
            }
        });
        if (state.chatInfo?.client) {
            state.chatInfo.client.tags = payload.tags
            state.chatInfo.client.client_status = payload.client_status
            state.chatInfo.client.next_contact_date = payload.next_contact_date
        }
    },
    [SET_REMINDERS](state, payload) {
        state.reminders = payload;
    },
    ['REMOVE_REMINDER'](state, payload) {
        let index = state.reminders[payload.name].findIndex(i => i.id === payload.id);
        state.reminders[payload.name].splice(index, 1);
    },
    ['ADD_REMINDER'](state, payload) {
        state.reminders[payload.name].push(payload.data);
    },
    [SET_UPDATE_CLIENT](state, payload) {
        state.chats.forEach(i => {
            if (i.client.id === payload.id) {
                i.client.tags = payload.tags;
                i.client.client_status = payload.client_status;
            }
        });
        if (state.chatInfo) {
            state.chatInfo.client.tags = payload.tags;
            state.chatInfo.client.responsible_user = payload.responsible_user;
            state.chatInfo.client.client_status = payload.client_status;
            state.chatInfo.client.last_deal = payload.last_deal;
        }

        for (let r in state.reminders) {
            state.reminders[r].forEach(i => {
                if (i.client_id === payload.id) {
                    i.client_status_color = payload.client_status.color;
                    i.client_status_name = payload.client_status.name;
                }
            });
        }
    },
    [SET_CHAT_SETTINGS](state, payload) {
        state.settings = payload;
    }
};
