import {SET_TASK, SET_TASKS} from "../../mt";

export default {
    [SET_TASKS](state, payload) {
        state.tasks = payload;
    },
    [SET_TASK](state, payload) {
        state.task = payload;
    },
};
