<template>
  <v-card class="modal-create settings">
    <v-form ref="form">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Общие настройки сделок</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-radio-group
              v-model="autoCreateDeal"
              class="evi-radio-button-group"
          >
            <template v-slot:label>
              Автоматическое создание сделки
              <v-menu
                  top
                  offset-y
                  open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  Если клиент пишет впервые или после успешного закрытия последней сделки,
                  <br>
                  при ответе сотрудника система сама создает сделку
                  <br>
                  с заполненными полями по умолчанию.
                </v-list-item>
              </v-menu>
            </template>
            <div class="d-flex align-center">
              <v-radio
                  v-for="item in radios"
                  :label="item.name"
                  :value="item.value"
                  :key="item.value"
                  class="mb-0 mr-6 evi-radio"
                  color="#45BF6A"
              />
            </div>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <small :style="{color: '#808386'}">
            Статус сделки по умолчанию
            <v-menu
                top
                offset-y
                open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
              </template>
              <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                Настройка отвечает за то, какой статус по умолчанию
                <br>
                присваивается сделке при автоматическом создании
              </v-list-item>
            </v-menu>
          </small>
          <v-autocomplete
              class="evi-autocomplete mb-2"
              color="#44D370"
              :items="localStatuses"
              v-model="autoStatus"
              item-text="name"
              item-value="id"
              clearable
              item-color="green"
              hide-details
              placeholder="Выберите статус"
          >
            <template v-slot:append>
              <span class="evi-autocomplete__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
            <template v-slot:selection="{item}">
              <div class="modal-create__color-select">
                <span class="color" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item="{item}">
              <div class="modal-create__color-select">
                <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
            cols="12"
        >
          <v-radio-group
              v-model="dealSearch"
              class="evi-radio-button-group"
          >
            <template v-slot:label>
              Поиск точного совпадения
              <v-menu
                  top
                  offset-y
                  open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  Если включена эта настройка, то поиск в разделе сделок будет искать одно точное совпадение,
                  <br>
                  а не все результаты, какие включают в себя поисковый запрос.
                </v-list-item>
              </v-menu>
            </template>
            <div class="d-flex align-center">
              <v-radio
                  v-for="item in radios"
                  :label="item.name"
                  :value="item.value"
                  :key="item.value"
                  class="mb-0 mr-6 evi-radio"
                  color="#45BF6A"
              />
            </div>
          </v-radio-group>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "DealSettingsModal",
  data: () => ({
    autoCreateDeal: false,
    dealSearch: false,
    autoStatus: null,
    radios: [
      {
        name: 'Включено',
        value: true,
      },
      {
        name: 'Выключено',
        value: false,
      },
    ],

  }),
  computed: {
    ...mapState('deals', ['settings']),
    ...mapState("deal-statuses", ["statuses",]),
    localStatuses() {
      let array = [];
      if (this.statuses.length) {
        array = this.statuses;
      } else {
        if (this.autoStatus) {
          array.push(this.autoStatus);
        }
      }
      return array;
    }
  },
  methods: {
    ...mapActions({
      getDealsSettings: 'deals/getDealsSettings',
      setSettings: 'deals/setDealsSettings',
    }),
    closeDialog () {
      this.$emit('close');
    },
    save () {
      this.setSettings({
        deals_auto_create: this.autoCreateDeal,
        deals_default_status_id: this.autoStatus,
        deals_search_exact: this.dealSearch,
      });
    }
  },
  mounted() {
    this.getDealsSettings();
  },
  watch: {
    settings (val) {
      if (val) {
        this.autoCreateDeal = val.deals_auto_create;
        this.autoStatus = val.deals_default_status_id;
        this.dealSearch = val.deals_search_exact;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
