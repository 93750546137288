import {SET_BILL, SET_BILLS} from "../../mt";

export default {
    [SET_BILLS](state, payload) {
        state.bills = payload;
    },
    [SET_BILL](state, payload) {
        state.bill = payload;
    },
};
