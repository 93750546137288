<template>
  <div>
    <v-menu offset-y :close-on-content-click="false" v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn color="#44D370" class="evi-button-filter-calendar" :class="isActiveFilter">
            <div class="evi-button-filter-calendar__count" v-if="isActiveFilter">{{filterCount}}</div>
            <img width="18" v-else-if="!isDark" :src="require('@/assets/img/common/sort-white.svg')" alt="">
            <img width="18" v-else :src="require('@/assets/img/common/sort.svg')" alt="">
          </v-btn>
          <div class="filter-title">Фильтр</div>
        </div>
      </template>
      <slot name="sort-menu"/>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "EsSort",
  props: {
    filter: Object,
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    isDark () {
      return this.$vuetify.theme.dark;
    },
    filterCount () {
      let count = 0;

      for (const key in this.filter) {
        if (this.filter[key] && key !== 'filter[integration.type]') {
          count++;
        }
      }

      return count;
    },
    isActiveFilter () {
      if (this.filterCount) {
        return 'filter-active';
      }
      return '';
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-title {
  display: inline-block;
  font-size: 14px;
  color: $evi-headline-color;
  margin-left: 12px;
}
</style>
