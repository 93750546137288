import {AxiosWrapper} from '@/api/AxiosWrapper';
import store  from '@/store';
import {SET_AUTOMATIONS} from "../../mt";

export default {
    getAutomationsPages (context, payload) {
        return AxiosWrapper.get(`/api/automations`, {queryParams: {...payload}}).then((response) => {
            context.commit(SET_AUTOMATIONS, response.data.response.data);
            store.commit('common/' + 'SET_TABLE_META', response.data.response.meta);
            return response;
        });
    },
    createAutomation (context, payload) {
        return AxiosWrapper.post(`/api/automations`, payload).then((response) => {
            context.commit('SET_AUTOMATION', response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Автоматизация создана!`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    editAutomation (context, payload) {
        return AxiosWrapper.put(`/api/automations/${payload.id}`, payload).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data.response;
        });
    },
    editAutomationActions (context, payload) {
        return AxiosWrapper.post(`/api/automations/${payload.id}/actions`, payload.data).then((response) => {
            context.commit('SET_AUTOMATION', response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Изменения сохранены`,
                    type: 'success',
                    timeout: 2000,
                },
                { root: true }
            );
            return response.data.response;
        });
    },
    deleteAutomation (context, payload) {
        return AxiosWrapper.delete(`/api/automations/${payload}`).then((response) => {
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Автоматизация удалена`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
    deleteAutomationTask (context, payload) {
        return AxiosWrapper.delete(`/api/automations/${payload.auto}/actions/${payload.task}`).then((response) => {
            context.commit('SET_AUTOMATION', response.data.response);
            context.commit(
                'common/ADD_ALERT',
                {
                    text: `Задание удалено`,
                    type: 'warning',
                    timeout: 2000,
                },
                { root: true }
            );
            return response;
        });
    },
};
