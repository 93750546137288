import store from '@/store';

function hasRight(right) {
  const roles = store.state.user.profile.roles;
  if (roles && roles.length) {
    let permissions = [];
    for (let i in roles) {
      if (Object.hasOwnProperty.call(roles, i)) {
        permissions = permissions.concat(roles[i].permissions);
      }
    }
    // если right это массив, то проверяется, что хотя бы один из элементов массива есть в permissions
    if (Array.isArray(right)) {
      return right.some((a) => permissions.some((p) => p.name === a));
    }
    return permissions.some((a) => a.name === right);
  } else {
    return false;
  }
}

function hasRightInclude(right) {
  const roles = store.state.user.profile.roles;
  if (roles && roles.length) {
    let permissions = [];
    for (let i in roles) {
      if (Object.hasOwnProperty.call(roles, i)) {
        permissions = permissions.concat(roles[i].permissions);
      }
    }
    // если right это массив, то проверяется, что хотя бы один из элементов массива есть в permissions
    if (Array.isArray(right)) {
      return right.some((a) => permissions.some((p) => p.name === a));
    }
    return permissions.some((a) => a.name.includes(`${right}`));
  } else {
    return false;
  }
}

export {hasRight, hasRightInclude};
