import {SET_ACCESS_TOKEN, SET_PROFILE, SET_TENANT, SET_WEBSOCKET_DATA} from "../../mt";

export default {
    [SET_PROFILE](state, payload) {
        state.profile = payload;
    },
    [SET_TENANT](state, payload) {
        state.tenant = payload;
    },
    [SET_ACCESS_TOKEN](state, payload) {
        state.accessToken = payload;
    },
    [SET_WEBSOCKET_DATA](state, payload) {
        state.webSocketData = payload;
    },
};
