import {SET_RIGHTS, SET_ROLES} from "../../mt";

export default {
    [SET_ROLES](state, payload) {
        state.roles = payload;
    },
    ['CHANGE_SELECTED_ROLE'](state, payload) {
        state.roles.forEach((el, i) => {
            if (el.id === payload.id) {
                state.roles.splice(i, 1, payload);
            }
        });
    },
    [SET_RIGHTS](state, payload) {
        state.rights = payload;
    },
};
