import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { createStore } from 'vuex-extensions';

import modules from './modules';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: 'evi-sales',
    modules: ['user'],
});

const store = createStore(Vuex.Store, {
    modules: {
        ...modules,
    },
    plugins: [vuexLocal.plugin],
});

export default store;
